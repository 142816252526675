import { Helmet } from "react-helmet";
import background from './Background/Lesson9.png'
import yougotthis from './images2/yougotthis.jpg'
import congrats from './images2/congrats.gif'
import userService from '../services/user'
import sticker from './sticker/disneyworld.webp'
import { useDispatch, useSelector } from 'react-redux'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'

const Lesson9 = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])
    const user = useSelector(state => state)
    const p1 = () => {
        let name = document.getElementById('name1-input').value;
        let outputElement = document.getElementById('output1');
        if (name === "completed" || name === "Completed") {
            if (user.lesson === 9 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            }
            else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p2 = () => {
        let name = document.getElementById('name2-input').value;
        let outputElement = document.getElementById('output2');
        if (user.lesson === 9 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 9 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            }
            else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p3 = () => {
        let name = document.getElementById('name3-input').value;
        let outputElement = document.getElementById('output3');
        if (user.lesson === 9 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 9 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            } else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p4 = () => {
        let name = document.getElementById('name4-input').value;
        let outputElement = document.getElementById('output4');
        if (user.lesson === 9 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 9 && user.question === 4) {
                const params = { id: user.id, lesson: true, number: 10 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 9!'
        }
    }
    if (user == null) {
        return <div></div>
    }
    if (user.lesson < 9) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    return (
        <div>
            <Helmet>
                <style>{'body {background-color: red; color: black}'}</style>
                <script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"></script>
            </Helmet>
            <div style={{ height: 25 }}></div>
            <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover', height: 550, padding: 100, backgroundPosition: 'center'
            }}>
            </div>
            <div style={{ margin: 100, textAlign: 'center' }}>
                <h1 style={{ fontSize: 100, margin: 10 }}>Lesson 9</h1>
                <p style={{ fontSize: 30, color: 'black', textAlign: 'center' }}><em>Problems(Hard)</em></p>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Round 3</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Time for some harder problems! Same drill as last time. Remember, you can check your progress on the home page!
                    This time DisneyWorld tickets on the line.<br />
                    GOOD LUCK!
                </p>
                <img src={yougotthis} alt="Hello" style={{ height: 375, margin: 100 }} /><br />
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. Given an int array arr, return true if there are two elements in a row that equal to 5.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5vhq?rw=1&arg=0&stdin=0" title="lesson9-problem1" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" in the box below to confirm the completion of the problem! (Make sure the confirmation pops up).</p>
                <input id="name1-input" style={{ width: 300 }} /><br />
                <button onClick={p1}>
                    Submit
                </button>
                <p id="output1"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    2. Given an array of ints, arr, return true if there are three even or three odd elements next to each other.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5vhv?rw=1&arg=0&stdin=0" title="lesson9-problem2" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name2-input" style={{ width: 300 }} /><br />
                <button onClick={p2}>
                    Submit
                </button>
                <p id="output2"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. We are tasked with filling a bucket of milk. The bucket is a total of "z" ounces. We have "x" amount of 10
                    ounce bottles and "y" amount of one ounce containers. Return whether or not we can fill up the bucket with
                    the exact amount of milk.<br />
                    Ex. x=4, y=3, z=13. We can use one 10 ounce bottle and three 1 ounce containers to fill 13 ounces.<br />
                    Ex. x=4, y=2, z=13. We are not able to make 13 because while we can make 10 and 20, we can't make 13 as we
                    only have
                    two 1 ounce containers.<br />
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5vi1?rw=1&arg=0&stdin=0" title="lesson9-problem3" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name3-input" style={{ width: 300 }} /><br />
                <button onClick={p3}>
                    Submit
                </button>
                <p id="output3"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    4. We are given two rectangles on the x,y plane. We will represent each rectangle with a set of 4 numbers.<br />
                    x1,y1,x2,y2 represents the first rectangle. (x1,y1)  is the bottom left corner. (x2,y2) is the top left corner.<br />
                    x3,y3,x4,x4 represents the second rectangle. (x3, y3) is the bottom left corner. (x4,y4) is the top left corner.<br />
                    We know that x1{"<"}=x3 and y1{"<"}=y3.<br />
                    Assume a farmer plans each rectangle with wheat. Return an int that represents the total area painted. Be aware
                    of overlap!<br />
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5vi5?rw=1&arg=0&stdin=0" title="lesson9-problem4" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name4-input" style={{ width: 300 }} /><br />
                <button onClick={p4}>
                    Submit
                </button>
                <p id="output4"></p>
            </div>
            <div style={{ margin: 100 }}>
                {user.lesson > 9 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} />
                </p>) : (<div></div>)}
                {user.lesson > 9 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <Link to="/stickers">Stickers</Link>
                </p>) : (<div></div>)}
            </div>
            {user.lesson > 9 ? (<div style={{ margin: 100 }}>
                <img src={congrats} alt="Hello" style={{ height: 350, margin: 100 }} /><br />
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Congrats! You are a Codepetitor! We are all so proud of you. You really are a special kid and your future is
                    extremely bright!<br />
                    As for next steps, first give yourself a break. Go watch some baseball, get some sleep, or, if your lucky,
                    Go to DISNEYLAND!<br />
                    After you celebrate accordingly, please look at the  <Link to="/nextsteps">Next Steps</Link> page with your parent/teacher. It will
                    direct as you continue your journey! Good luck!<br />
                </p>

            </div>) : (<div></div>)}


        </div>
    )
}

export default Lesson9