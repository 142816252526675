import ReactDOM from "react-dom/client";
import React from 'react'
import App from "./App";
import "./css/styles.css"
import "./css/main.css"
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import userreducer from "./reducers/userreducer";
import { HashRouter } from 'react-router-dom'


const store = createStore(userreducer)

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <React.StrictMode>
            <HashRouter>
                <App />
            </HashRouter>
        </React.StrictMode>
    </Provider>
)