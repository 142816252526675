import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    incrementQuestion(state, action) {
      state.question = state.question + 1
      return state
    },
    incrementLesson(state, action) {
      state.lesson = state.lesson + 1
      state.question = 1
      return state
    },
    setU(state, action) {
      return action.payload
    }
  },
})


export const { incrementQuestion, incrementLesson, setU } = userSlice.actions
export default userSlice.reducer