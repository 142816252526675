import axios from 'axios'
const baseUrl = 'https://codepetitor.onrender.com/api/users'

const signUp = async credentials => {
  const response = await axios.post(baseUrl, credentials)
  return response.data
}

const getUser = async credentials => {
  const response = await axios.get(`${baseUrl}/${credentials.id}`)
  return response.data
}

const increment = async (params) => {
  const response = await axios.put(`${baseUrl}/${params.id}`, { lesson: params.lesson, num: params.number })
  return response.data
}

const UserService = { signUp, getUser, increment }
export default UserService