import { Link } from "react-router-dom"

const invalid = () => {
    return (
        <div style={{ margin: 20 }}>
            <p style={{ lineheight: 2.0, fontSize: 20 }}>
                Please navigate to the <Link to="/">Home</Link> page to Login or Sign Up.
            </p>
        </div>
    )
}

export default invalid
