import math1 from './Images/math.webp'
import cool from './Images/cool.gif'
import greatjob from './Images/greatjob.jpg'
import aligator from './images2/Aligator.png'
import background from './Background/Lesson3.png'
import sticker from './sticker/littlemermaid.jpg'
import { Helmet } from "react-helmet";
import userService from '../services/user'
import { useDispatch, useSelector } from 'react-redux'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'

const Lesson3 = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])
    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    if (user.lesson < 3) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    const p1 = () => {
        let inputElement = document.getElementById('name-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        let outputElement = document.getElementById('output');
        if (name === 'System.out.println(101+202);') {
            if (user.lesson === 3 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p2 = () => {
        let inputElement = document.getElementById('name1-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        const b1 = (name === 'inta=10;');
        let inputElement1 = document.getElementById('name2-input');
        let name1 = inputElement1.value;
        name1 = name1.replace(/\s/g, '');
        const b2 = (name1 === 'intb=20;');
        let inputElement2 = document.getElementById('name3-input');
        let name2 = inputElement2.value;
        name2 = name2.replace(/\s/g, '');
        const b3 = (name2 === 'intc=a+b;' || name2 === 'intc=b+a;');
        let inputElement3 = document.getElementById('name4-input');
        let name3 = inputElement3.value;
        name3 = name3.replace(/\s/g, '');
        const b4 = (name3 === 'intd=a*b;' || name3 === 'intd=b*a;')
        let inputElement4 = document.getElementById('name5-input');
        let name4 = inputElement4.value;
        name4 = name4.replace(/\s/g, '');
        const b5 = (name4 === 'inte=b/a;')
        let outputElement = document.getElementById('output5');
        if (user.lesson === 3 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (b1 && b2 && b3 && b4 && b5) {
            if (user.lesson === 3 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }

    const p7 = () => {
        let inputElement = document.getElementById('name6-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        let outputElement = document.getElementById('output6');
        if (user.lesson === 3 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'System.out.println(Math.max(a,b));' || name === 'System.out.println(Math.max(b,a));') {
            if (user.lesson === 3 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p8 = () => {
        let inputElement = document.getElementById('name7-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        let inputElement2 = document.getElementById('name8-input');
        let name2 = inputElement2.value;
        name2 = name2.replace(/\s/g, '');
        let inputElement3 = document.getElementById('name9-input');
        let name3 = inputElement3.value;
        name3 = name3.replace(/\s/g, '');
        let inputElement4 = document.getElementById('name10-input');
        let name4 = inputElement4.value;
        name4 = name4.replace(/\s/g, '');
        let outputElement = document.getElementById('output10');
        const b1 = (name === 'inta=7;');
        const b2 = (name2 === 'intb=110;');
        const b3 = (name3 === 'intc=b/a;');
        const b4 = (name4 === 'intd=b%a;');
        if (user.lesson === 3 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (b1 && b2 && b3 && b4) {
            if (user.lesson === 3 && user.question === 4) {
                const params = { id: user.id, lesson: true, number: 4 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 3! Check out your new sticker and get started with Lesson 4 when you are ready!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }

    return (
        <div>
            <title>Lesson 3</title>
            <Helmet>
                <style>{'body { background-color: #8B0000; color: #D3D3D3}'}</style>
            </Helmet>
            <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: 550, padding: 150, backgroundPosition: 'center' }}>
            </div>
            <div style={{ height: 10 }}>
                <p style={{ fontSize: 30, textAlign: 'center' }}><em>Arithmetic</em></p>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Introduction to Arithmetic</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    What if you were told a computer could do math? Well, I don’t think you are that surprised.
                    Computers are extremely smart. Of course they can do math! In Java, we can do addition, subtraction,
                    multiplication, division, and a ton of other arithmetic functions. Let’s focus on the basics first.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Addition, use the "+" sign. 1+4=5.<br />
                    Subtraction, use the "-" sign, 3-2=1. <br />
                    Multiplication, use the "*" sign. 5*4=20.<br />
                    Division, use the "/" sign. 6/3=2. <br />
                </p>
                <img src={math1} alt="World" style={{ height: 375, margin: 100, alignSelf: 'flex-end' }} />
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    How are we going to use these in a code? One thing we can do is print it out directly, as follows.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        System.out.println(1+3);<br />
                        System.out.println(5*7);
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    As we can see here, our statements print out 4 and 35 to the system. One thing to note is that for numbers,
                    we don’t need quotes. Look what happens when we use quotes.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        System.out.println(“1+3”);
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    It will literally print "1+3", not 4. So be careful! Reviewing what we learned from the previous lesson,
                    “1+3” is a String because of the quotations! However, 1+3, or 4, is an integer.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <h1>Other Arithmetic Operations</h1>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        We can do a bunch of other cool math in Java. However, the way we do it is a bit different that simple operations
                        such as addition. Java has some special functions it can perform, such as taking the absolute value, finding the
                        maximum value of two numbers, etc. We can all some functions when we type in “Math.function_name(function_parameters);”.
                        We replace function_name with the name of the function. We replace function_parameters with the numbers we want to apply
                        the function to. Parameters are simply what you input into the function. It’s like the “x” in “f(x)”.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        If you don’t understand, don’t worry. Initially, you can just copy what I gave you and try to memorize it!
                        Eventually, you will start to understand the different components of it. That’s a great way to learn!
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        These are the three functions we will be learning today. <br />
                        <b>1. </b><code>Math.max(a,b);</code> This returns the larger value between a and b.<br />
                        <b>2. </b><code>Math.min(a,b);</code> This returns the smaller value between a and b. <br />
                        <b>3. </b><code>Math.pow(a,b);</code> This returns a to the power of b. Returns a double value. <br />
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        The function names are max, min, and pow, and the parameters are integers a and b. It’s that simple! All we must
                        do is call these functions to return the correct value. Let’s try printing out a few of these operations.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>System.out.println(Math.max(10,5));</code> This will print 10, which is greater than 5. <br />
                        <code>System.out.println(Math.max(11,4));</code> This will print 11, which is greater than 4.<br />
                        <code>System.out.println(Math.min(12,4));</code> This will print 4, which is less than 12.<br />
                        <code>System.out.println(Math.min(7,7));</code> This will print 7, since it's the smallest/only number.<br />
                        <code>System.out.println(Math.pow(5,3));</code> This will print 125, since 5*5*5=125.<br />
                        <em>Caution: this will be a double value, not an integer! The function Math.pow always returns a double.</em>
                    </p>
                    <img src={aligator} style={{ height: 400, margin: 100 }} alt="GOAT." />
                    <h1>Math with Variables</h1>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Instead of using numbers as we did previously, we can use variables we created earlier in the program.
                        Assume we created integers x and y earlier. We can add, subtract, etc.. these two variables to get the value.
                    </p>

                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>
                            int x=10;<br />
                            int y=11;<br />
                            System.out.println(x+y);<br />
                            System.out.println(x*y);<br />
                            System.out.println(x);<br />
                            int z=x+y;
                        </code>
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Let's go over what this code does. The first two lines create variables x and y, which we set to 10 and 11.
                        We then print out x+y, which is 21. Printing out x*y, we get 110. In line 5, we directly print out
                        the value of a variable. This prints 10, the value of x. If we program
                        <code>System.out.println("x")</code>, we
                        will end up printing the character x, which is not what we want. Remember, the quotations make it a String!
                        Finally, we can also create a new variable and set it to equal some combination of other variables. In this
                        case, we have z=x+y, which results in z being equal to 21.
                    </p>
                    <img src={cool} style={{ height: 400, margin: 100 }} alt="GOAT." />
                    <h1>Special Topic</h1>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Let’s look at one more concept before we end the lesson. Check out the following code down below.<br />
                        <code>
                            int x=10;<br />
                            int y=6;<br />
                            x/y=?<br />
                        </code>
                        Is the answer 1.6666666...? No! In Java, x/y=1.
                        When we divide 2 integers by each other, we get the answer without the remainder.
                        Essentially, the process removes everything to the right of the decimal, only keeping
                        the integer part. The process of dividing two integers is called integer division.
                        The quotient from integer division will always be an integer! Be sure to keep this in mind.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        How do we find the remainder?

                        Instead of /, we use %. Assuming x and y are integers,
                        x%y will guess us the remainder of x divided by y.
                        <br />
                        <code>
                            10%3=1<br />
                            11%4=3<br />
                            19%5=4<br />
                        </code>
                        What if we want the decimal?
                        As we mentioned previously, use the double variable type for decimal numbers. Java won’t allow us to produce
                        decimals from integers. We have two ways to approach this.
                    </p>
                    <h2>Option 1: Use Doubles</h2>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        This will allow us to have decimals outputs. This is because the double variable type was designed to represent
                        decimals. See the example down below. <br />
                        <code>
                            double a=10;<br />
                            double b=3;<br />
                            System.out.println(a/b); <br />
                        </code>
                        This code will print 3.33333333…
                    </p>
                </p>
                <h2>Option 2: Cast the Integers to Doubles (harder)</h2>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    We can also cast our integers to doubles. This means that when we divide, we want to specify that
                    we want the integers to act as doubles for just the expression. In other words, we are telling the computer
                    that for this line of code, our ints are now doubles, allowing the computer to output a value with decimals.
                    We do this by writing (double) next to the variable we want to cast as a double.
                    <br />
                    <code>
                        int a=10; <br />
                        int b=10;<br />
                        System.out.println((double)a/(double)b);<br />
                    </code>
                    This code will print 3.33333333…
                    When we continue on with our program, a and b are still integers. Remember, they are only doubles for that line of code.
                </p>
                <img src={greatjob} style={{ height: 400, margin: 100 }} alt="GOAT." />
            </div>
            <div style={{ margin: 100 }}>
                <h1>Practice Problems</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. Print the sum of 101 and 202 (Don't do System.out.println(303);, rather show the addition sign!). <br />
                    <input id="name-input" style={{ width: 300 }} />
                    <button onClick={p1}>
                        Check
                    </button>
                    <p id="output"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <p>
                        2. Create two int variables. One with a value of 10 named "a" and another with a value of 20 named "b".<br />
                        Create a:<br />
                        <input id="name1-input" style={{ width: 300 }} /><br />
                    </p>
                    <p>
                        Create b:<br />
                        <input id="name2-input" style={{ width: 300 }} /><br />
                    </p>
                    <p>
                        Create an integer variable c, where c is equal to the sum of a and b(make sure this works for any possible values of a and b):<br />
                        <input id="name3-input" style={{ width: 300 }} /><br />
                    </p>
                    <p>
                        Create an integer variable d, where d is equal to a times b(make sure this works for any possible values of a and b): <br />
                        <input id="name4-input" style={{ width: 300 }} /><br />
                    </p>
                    <p>
                        Create an integer variable e, where e is equal to b divided by a: <br />
                        <input id="name5-input" style={{ width: 300 }} />
                        <button onClick={p2}>
                            Check
                        </button>
                    </p>
                    <p id="output5"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. Using Math.max, given two integers a and b print the larger number between the two using Math.max.<br />
                    <input id="name6-input" style={{ width: 300 }} />
                    <button onClick={p7}>
                        Check
                    </button>
                    <p id="output6"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <p>
                        4. Create two integer variables b and a. Set b to 110 and a equal to 7. Create variables integers c and d such
                        that c is equal to b/a without the remainder and let d be the remainder when a divides b. (Make sure your
                        code works for any values of a and b, not just the ones provided you assigned them to!)<br />
                        Create a:<br />
                        <input id="name7-input" style={{ width: 300 }} />
                    </p>
                    <p>
                        Create b:<br />
                        <input id="name8-input" style={{ width: 300 }} />
                    </p>
                    <p>
                        Create c:<br />
                        <input id="name9-input" style={{ width: 300 }} />
                    </p>
                    <p>
                        Create d:<br />
                        <input id="name10-input" style={{ width: 300 }} /><br />
                    </p>
                    <button onClick={p8}>
                        Check
                    </button>
                    <p id="output10"></p>
                </p>
                {user.lesson > 3 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} /><br />
                    <Link to="/stickers" style={{ color: 'white' }}>Stickers</Link>
                </p>) : (<div></div>)}
                {user.lesson > 3 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}><Link to="/lesson4" style={{ color: 'white' }}>Lesson 4</Link><br />
                </p>) : (<div></div>)}
            </div>

        </div>
    )
}

export default Lesson3