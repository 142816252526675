import lesson1 from './sticker/frozen.jpg'
import lesson2 from './sticker/cars.jpg'
import lesson3 from './sticker/littlemermaid.jpg'
import lesson4 from './sticker/tangeled.jpg'
import lesson5 from './sticker/toystory.webp'
import lesson6 from './sticker/lionking.jpg'
import lesson7 from './sticker/incredibles.jpg'
import lesson8 from './sticker/disneyland.jpg'
import lesson9 from './sticker/disneyworld.webp'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setU } from '../reducers/userreducer'
import userService from '../services/user'

const L1 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 1: Frozen</h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson1} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div style={{ margin: 10 }}>
                Whether you grew up when Frozen was the talk of the town or haven’t watched it yet, no one can deny the cultural
                impact Frozen has had. A movie detailing the relationship between two sisters, the music, memes, and beautiful
                storytelling make the story phenomenal. In 2019, Frozen 2 came out, continuing the story. I’d highly recommend
                both movies, as the story is awesome and the songs are unforgettable!
            </div>
        </p>
    </div>
)

const L2 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 2: Cars</h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson2} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div className="textbox">
                An all-time classic. While critics often list it as one of the less acclaimed Pixar movies, most people, especially teenage
                and adolescent boys, would strongly disagree. The character development, the electric car racing scenes, and the unforgettable
                characters make this movie what it is. And who doesn't love a cozy, small town like Radiator Springs? There are 2 more movies,
                Cars 2 and 3, which aren’t my favorite. However, Cars will always be one of my favorites. Life is a Highway Folks!
            </div>
        </p>
    </div>
)

const L3 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 3: The Little Mermaid</h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson3} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div className="textbox">
                What a classic. Adapted from the Hans Christen Anderson Fairytale, the Little Mermaid (1989) depicts a story of innocence,
                child-parent relationships, and love. While the original story is one of tragedy, the Disney movie is one to make you
                feel good. It’s awesome. Things do turn sideways when Ariel makes a deal with the devil, which I wouldn’t recommend.
                However, I would recommend you to watch the original movie. You will find out what happens. With amazing songs, likable characters, and many suspenseful moments, it will forever be a timeless classic.            </div>
        </p>
    </div>
)

const L4 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 4: Tangled </h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson4} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div className="textbox">
                Often regarded as one of the most underrated Disney movies, Tangled (2010) tells the tale of Rapunzel, whose long,
                tangled hair, possesses magical powers. That being said, an evil witch kidnaps her from the king and queen, her
                parents. As her journey to discover who she truly unfolds, she meets unforgettable characters, faces several obstacles,
                and may even find love along the way. As a bonus, the songs are fantasitc as well! Check it out!            </div>
        </p>
    </div>
)

const L5 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 5: Toy Story</h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson5} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div className="textbox">
                Every kid knows Toy Story(1995). On the surface, it’s just a bunch of toys that can talk and move around.
                This alone makes it extremely funny and entertaining. However, deeper stories and themes lie within the movie,
                which makes it considered one of the best pictures ever made by Disney. Lessons of friendship, relationships, teamwork,
                selflessness, and perseverance are prevalent as these toys navigate through the human world. Equally as good as the
                original are Toy Story 2, 3, and 4, which you should also check out.
            </div>
        </p>
    </div>
)

const L6 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 6: Lion King</h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson6} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div className="textbox">
                Often regarded as the best Disney movie of all time. Honestly, Beauty and the Beast is my favorite,
                but I got to say, the Lion King(1994) is up there. A fun adaptation of Hamlet, Lion King has it all:
                coming of age, tragedy, betrayal, and romance. There is a ton of action, emotion, and laughs. Like most
                Disney films, the music is diverse and well-written, with romantic, catchy, and memorable music. If, for
                some reason, you haven’t seen it, SEE IT.
            </div>
        </p>
    </div>
)

const L7 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 7: The Incredibles</h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson7} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div className="textbox">
                While Disney may not be known for their superhero movies, the Incredibles(2004) is an example of a film that features an iconic
                family of superheroes. While the movie may not have the same emotional or romantic themes other films possess, it keeps you on
                the edge of your seat. The characters are extremely interesting, the action is well-animated, and it’s overall a great film.
                Don't forget to check out Incredibles 2, the sequel to the original movie.
            </div>
        </p>
    </div>
)

const L8 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 8: Disneyland</h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson8} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div className="textbox">
                Located in Anaheim, California, Disneyland is the second most visited theme park in the world. There’s nothing
                like walking down the main street and seeing the castle for the first time, which resembles the one from the classic
                movie Sleeping Beauty. Besides that, there’s truly too much to do. From rides, restaurants, shops, and fireworks, it’s
                an experience that will live in your memory forever. I highly recommend taking two days to take in all the Disney-themed
                awesomeness it offers!
            </div>
        </p>
    </div>
)

const L9 = () => (
    <div style={{ margin: 50 }}>
        <h1>Lesson 9: Disneyworld</h1>
        <p style={{ lineheight: 2.0, fontSize: 20 }}>
            <img class="left" src={lesson9} style={{ height: 300, marginRight: 15 }} alt="GOAT." />
            <div className="textbox">
                Well, this one ups the previous sticker. DisneyWorld is the most visited theme park in the world. Unfortunately,
                I haven’t been there. That said, if I could visit one new place, it’d be Disney World. Think of Disneyland but on
                steroids. With four different parks, different water parks, and other awesome attractions, a week is probably barely
                enough to fully experience this place. Now that will be unforgettable.<br />
            </div>
        </p>
        <div style={{ height: 100 }}></div>
        <p style={{ textAlign: 'center', lineheight: 2.0, fontSize: 30 }}>
            Congrats on winning all the stickers!
        </p>

    </div>
)
const Stickers = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
                .catch(error => {
                    console.log('fail')
                })
        }
        // eslint-disable-next-line
    }, [])
    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    return (
        <>
            <div>
                {user.lesson === 1 ? <p style={{ lineheight: 2.0, fontSize: 20 }}>Complete a Lesson to win your first Sticker!</p> : <div></div>}
                {user.lesson > 1 ? <>{L1()}</> : <div></div>}
                {user.lesson > 2 ? (<><div style={{ height: 125 }}></div>{L2()}</>) : <div></div>}
                {user.lesson > 3 ? (<><div style={{ height: 125 }}></div>{L3()}</>) : <div></div>}
                {user.lesson > 4 ? (<><div style={{ height: 125 }}></div>{L4()}</>) : <div></div>}
                {user.lesson > 5 ? (<><div style={{ height: 125 }}></div>{L5()}</>) : <div></div>}
                {user.lesson > 6 ? (<><div style={{ height: 125 }}></div>{L6()}</>) : <div></div>}
                {user.lesson > 7 ? (<><div style={{ height: 125 }}></div>{L7()}</>) : <div></div>}
                {user.lesson > 8 ? (<><div style={{ height: 125 }}></div>{L8()}</>) : <div></div>}
                {user.lesson > 9 ? (<><div style={{ height: 125 }}></div>{L9()}</>) : <div></div>}
            </div>
            <div style={{ height: 100 }}></div>
        </>
    )
}

export default Stickers