import { Helmet } from "react-helmet";
import background from './Background/Lesson7.png'
import gotime from './images2/gotime.jpg'
import userService from '../services/user'
import sticker from './sticker/incredibles.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'

const Lesson7 = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])
    const user = useSelector(state => state)
    const p1 = () => {
        let name = document.getElementById('name1-input').value;
        let outputElement = document.getElementById('output1');
        if ((name === "completed" || name === "Completed")) {
            if (user.lesson === 7 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            } else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p2 = () => {
        let name = document.getElementById('name2-input').value;
        let outputElement = document.getElementById('output2');
        if (user.lesson === 7 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if ((name === "completed" || name === "Completed")) {
            if (user.lesson === 7 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            } else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p3 = () => {
        let name = document.getElementById('name3-input').value;
        let outputElement = document.getElementById('output3');
        if (user.lesson === 7 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if ((name === "completed" || name === "Completed")) {
            if (user.lesson === 7 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            } else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p4 = () => {
        let name = document.getElementById('name4-input').value;
        let outputElement = document.getElementById('output4');
        if (user.lesson === 7 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if ((name === "completed" || name === "Completed")) {
            if (user.lesson === 7 && user.question === 4) {
                const params = { id: user.id, lesson: false, number: 5 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            } else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p5 = () => {
        let name = document.getElementById('name5-input').value;
        let outputElement = document.getElementById('output5');
        if (user.lesson === 7 && user.question <= 4) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 7 && user.question === 5) {
                const params = { id: user.id, lesson: false, number: 6 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            }
            else {
                outputElement.innerText = 'Already Completed.'

            }
        }
    }
    const p6 = () => {
        let name = document.getElementById('name5-input').value;
        let outputElement = document.getElementById('output6');
        if (user.lesson === 7 && user.question <= 5) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 7 && user.question === 6) {
                const params = { id: user.id, lesson: true, number: 8 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 7! Check out your new sticker and get started with Lesson 8 when you are ready!'
        }
    }

    if (user == null) {
        return <div></div>
    }
    if (user.lesson < 7) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    return (
        <div>
            <Helmet>
                <style>{'body {background-color: white; color: black}'}</style>
                <script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"></script>
            </Helmet>
            <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover', height: 600, padding: 50, backgroundPosition: 'center'
            }}>
            </div>
            <div>
                <p style={{ fontSize: 30, color: 'black', textAlign: 'center' }}><em>Problems(Easy)</em></p>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Introduction</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Congrats! You made it! You got through 6 hard lessons and earned 6 amazing stickers! Be proud of
                    yourself!<br />
                    It's go time. You know how to code now. It's time to put your skills to the test. Next step, problem solving.<br />
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Here, I'm going to give you problems to solve. You will be given a problem statement. You need to write a
                    code to solve the problem. <br />
                    However, the format may look a bit weird. Let me give an example and explain as best as possible what to do.
                </p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Example:<br />
                    1. You are given a boolean f that indicates whether or not you fail the test. Return true if you fail
                    and false if you pass.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uLL?rw=1&arg=0&stdin=0" title="lesson7-problem" width={1200} height={450}></iframe>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Where should we start? For each problem, check where to write the code. In this case,
                    we want you to write the function tf. Look for tf. It should say "static boolean tf(boolean f)". We want to
                    write our code in here. Let's go over what this means.<br />
                    <ul style={{ fontSize: 20 }}>
                        <li>Ignore static. </li>
                        <li> "Boolean" is the return type. At the end of the code, you must return a boolean value.
                            This means either true, false, or a boolean value/expression (Lesson 4).</li>
                        <li> "tf" is the name of the function. Within it, it will say to write the code.</li>
                        <li>boolean f is a variable that you don't know. You need to assume it's already declared
                            and solve the problem to work for ANY value of f. </li>
                    </ul>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Basically, given the variables inside the parenthesis, you need to write a code that does what the problem tells you to do.
                    So for this problem, it's simple. We print out f. If f is true it will print true. If f is false we print false. BUT.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    In a function, we return instead of print. How do you return something? Think about it this way.
                    Let's say we want to print the value of f. We write <code>System.out.println(f);</code>. In a function,
                    we want you to "return" something. All you need to do is type in <code> return "answer";</code>
                    Therefore, we can just type in "return f;" Type "return f;"(without the quotations) in "type code below" and try running the code
                    above.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    It should say Correct! When you solve the real questions, follow the instructions in the input box to save your progress.
                    Remember, you can check your progress on the home page!
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    In the future, it's going to be similar. If the question asks to return an integer and we have
                    our answer in the variable x, we will type "return x;" at the end of the function to get our answer! I'll
                    explain the first question as well!
                </p>
            </div>
            <img src={gotime} alt="Hello" style={{ height: 375, margin: 100 }} /><br />
            <div style={{ margin: 100 }}>
                <h1>Problems</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. The boolean weekday is true if it is a weekday, and the parameter vacation is true if we are on vacation.
                    We wake up early if it's a weekday and we are not on vacation. Return true if we wake up early.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uVg?rw=1&arg=0&stdin=0" title="lesson7-problem1" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Here, we want to return a boolean on whether or not we need to wake up early. The condition for
                    that is that it must be a weekday and we must NOT be on vacation. We can create a boolean that represents this:
                    weekday&&!vacation. As we learned in lesson 4, && is the logical AND operation and !is the logical NOT. Thus, what we wrote means
                    weekday and also not on vacation. Therefore, we type in " return weekday&&!vacation;" at the place it asks you to do. Try for
                    yourself!
                </p>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" in the box below to confirm the completion of the problem! (Make sure the confirmation pops up).</p>
                <input id="name1-input" style={{ width: 300 }} /><br />
                <button onClick={p1}>
                    Submit
                </button>
                <p id="output1"></p>
            </div>

            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    2. Given an int n, return twice n if n is less than 21. Else, return n-21.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uVr?rw=1&arg=0&stdin=0" title="lesson7-problem2" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name2-input" style={{ width: 300 }} /><br />
                <button onClick={p2}>
                    Submit
                </button>
                <p id="output2"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. Given two booleans twin1 and twin2 indicating two twins' responses when asked if they want McDonald's, return true if both have the same response.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5vBg?rw=1&arg=0&stdin=0" title="lesson7-problem3" width={1200} height={550}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name3-input" style={{ width: 300 }} /><br />
                <button onClick={p3}>
                    Submit
                </button>
                <p id="output3"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    4. Given two ints a and b, return their sum unless they are equal to each other. If they are equal to each other, return triple their sum.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uVA?rw=1&arg=0&stdin=0" title="lesson7-problem4" width={1200} height={550}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name4-input" style={{ width: 300 }} /><br />
                <button onClick={p4}>
                    Submit
                </button>
                <p id="output4"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    5. Given two ints a and b, return true if they sum up to 11 or if one of them is equal to 11.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uVK?rw=1&arg=0&stdin=0" title="lesson7-problem5" width={1200} height={550}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name5-input" style={{ width: 300 }} /><br />
                <button onClick={p5}>
                    Submit
                </button>
                <p id="output5"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    6. Given an int array arr, return true if the first or last element of the array is equal to 5.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uVQ?rw=1&arg=0&stdin=0" title="lesson7-problem6" width={1200} height={600}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name6-input" style={{ width: 300 }} /><br />
                <button onClick={p6}>
                    Submit
                </button>
                <p id="output6"></p>
            </div>
            <div style={{ margin: 100 }}>
                {user.lesson > 7 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} /><br />
                    <Link to="/stickers">Stickers</Link>
                </p>) : (<div></div>)}
                {user.lesson > 7 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}><Link to="/lesson8">Lesson 8</Link>
                </p>) : (<div></div>)}
            </div>
        </div>
    )
}

export default Lesson7