import kidoncomp from './Images/motivation.jpg'
import cow from './images2/usacocow.png'
import scoot from './images2/scoot.jpg'
import background from './images2/thanks.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import userService from '../services/user'
import { setU } from '../reducers/userreducer'

const Nextsteps = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
                .catch(error => {
                    console.log('fail')
                })
        }
        // eslint-disable-next-line
    }, [])

    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    if (user.lesson < 10) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    return (
        <div>
            <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: 550, padding: 80, backgroundPosition: 'center' }}>
            </div>
            <div style={{ margin: 100 }}>
                <h1 style={{ fontSize: 100 }}>Next Steps</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    To students, parents, and teachers,<br />
                    Thank you for trusting and using my product. The next few resources do a
                    great job
                    of completing your competitive coding journey. Codepetitor is only the first chapter of a long story.
                </p>
                <img src={kidoncomp} style={{ height: 300, margin: 100 }} alt="Happy Kid." />
                <h1>CodingBat</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    My recommendation is to go to a website called CodingBat to do their problems. This is a great website
                    that offers problems that require a similar skill level to the ones from lessons 7-9.
                    This will allow for some great problem solving practice while refining your
                    critical thinking skills. </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    There may be some problems you know how to solve but can't figure out how to write the logic in code.
                    If you need help with this look at Codeacademcy (less focused on problem solving and more on how to code),
                    review Codepetitor lessons, or do a Google search! Google is a great resource if you a have a specific question, or want to seek
                    out new resources. <br />
                    <a href="https://codingbat.com/java">CodingBat</a>
                </p>
                <h1>USACO</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    After some more practice, I'd suggest you look into the big boy, USACO. For more on USACO, look <a
                        href="http://www.usaco.org/index.php">here</a>.<br /> Essentially, it's the largest
                    competitive coding contest in America for students who haven't graduated high school.
                    There are four levels: Bronze, Silver, Gold, and Platinum. Everyone starts at bronze. Each month
                    from December-March, there's a contest. In each contest, you complete problems at your current level. You will
                    be stuck in bronze for a while so don't worry. Be patient!</p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Fun fact: Every question in USACO is about Farmer John and his cows.
                </p>
                <img src={cow} style={{ height: 400, margin: 100 }} alt="Happy Kid." />

                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    After you master Codingbat, I strongly suggest you get started with the USACO Guide. Start slow, and don't
                    do everything at once.
                    Everytime you learn a section,
                    do some practice problems from previous competitions. I think the hardest part is the input output part.
                    It's very confusing
                    so focus on that. You will also have to learn about the "Main header" I was talking about in the first
                    lesson. Try to familiarize yourself with the different headers and the I/O. Reach out to me if you need help!
                    <a href="https://usaco.guide/general">USACO Guide</a>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Along with the USACO guide, you can use the USACO training page. It's a bit outdated but can complement the
                    USACO guide well.
                    <a href="https://train.usaco.org">USACO Training</a>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    As mentioned above, there's a collection of USACO problems from past contests. I suggest that after you complete, for
                    example, the "bronze" section of the USACO Guide, you work on the bronze level problems.
                    Once you master bronze, move on to the next levels and do the same thing. These will take time so be patient!
                </p>
                <img src={scoot} style={{ height: 400, margin: 100 }} alt="Happy Kid." />

                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    And last but not least, Competitions! Do competitions whenever you have the chance to. USACO is the best contest but also look at
                    Codeforces.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Practice makes perfect!
                </p>
                <h1>Classes</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Also, given the chance, take classes such as an JAVA/C++/AP CS A. They are more project
                    oriented and have a bigger focus on technical skills.
                </p>
                <h1>Final Words</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Keep grinding. Participate in USACO competitions and keep on looking for ways to expand your journey! Be
                    patient! It'll take time. <br />
                    Good Luck! I'm proud of you! Also keep liking Disney, even where your old!
                </p>
                <footer className="foot">
                    <h1>Feel free to reach out to me at itsbz@umich.edu</h1>
                    <p>Also, if you liked this course and think I should expand upon it, let me know!</p>
                </footer>
            </div>
        </div>
    )
}

export default Nextsteps