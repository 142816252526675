import kidcoding from './Images/kidcoding.jpg'
import cooldisney from './Images/cooldisney.jpg'

const Home = () => {
    return (
        <div>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="src/main.css" />
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Hello and Welcome to CODEPETITOR! In this experience, you will learn
                    how to code and solve problems with computers while having fun! How cool!

                    We have a series of topics that will take you through this journey. Each topic will consist of a lesson
                    teaching you all you need to know about each topic. There will be a few practice problems at the end so
                    you can truly master the subject.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Don’t know how to code? Don’t worry! You are a smart
                    kid. We will
                    teach you everything you need to know to
                    be able to beat the challenges. The coding language will be Java.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Every time you beat a level, you win a sticker! The more stickers, the better.
                    The first seven stickers are really cool Disney movies that you should watch! The last two are a suprise!
                </p>
                <p style={{ textAlign: 'center', fontSize: 20 }}><b>So what are you waiting for? Login or Create an Account to get Started! Good Luck!</b></p>
            </div>
            <div >
                <img src={kidcoding} alt="kidcoding" style={{ height: 350, margin: 100, alignSelf: 'flex-start' }} />

                <img src={cooldisney} alt="cooldisney" style={{ height: 350, margin: 100, alignSelf: 'flex-end' }} />
            </div>

            <footer className="foot">
                <h1>Made with lots of &#10084;&#65039; by Brian Zhang. Shoutout to my family! &#10084;&#65039;</h1>
            </footer>
            <footer>
                <p style={{ fontSize: 15 }}>If you have any questions, issues, or suggestions, please reach out to me at itsbz@umich.edu.</p>
            </footer>
        </div>
    )
}


export default Home