import greatjob from './Images/greatjob.webp'
import goat from './Images/goat.png'
import background from './Background/Lesson2.png'
import userService from '../services/user'
import sticker from './sticker/cars.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'

const Lesson2 = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])
    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    if (user.lesson < 2) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    const sayHello = () => {
        let inputElement = document.getElementById('name-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output');
        if (name === 'String s;') {
            if (user.lesson === 2 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const fix = () => {
        let inputElement = document.getElementById('name1-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output1');
        name = name.replace(/\s/g, '');
        if (user.lesson === 2 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'doublea=0.5;') {
            if (user.lesson === 2 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const tf = () => {
        let inputElement = document.getElementById('name2-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        let outputElement = document.getElementById('output2');
        if (user.lesson === 2 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'booleanbool=false;') {
            if (user.lesson === 2 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const kevin = () => {
        let inputElement = document.getElementById('name3-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        let inputElement1 = document.getElementById('name4-input');
        let name1 = inputElement1.value;
        name1 = name1.replace(/\s/g, '');
        let outputElement = document.getElementById('output4');
        if (user.lesson === 2 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'intnum=5;' && name1 === 'num=2;') {
            if (user.lesson === 2 && user.question === 4) {
                const params = { id: user.id, lesson: true, number: 3 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 2! Check out your new sticker and get started with Lesson 3 when you are ready!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    return (
        <div>
            <meta charSet="utf-8" />
            <title>Lesson 2</title>
            <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: 350, padding: 300, backgroundPosition: 'center' }}>
            </div>
            <div>
                <p style={{ fontSize: 30, color: 'black', textAlign: 'center' }}><em>Variables</em></p>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Introduction to Variables</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    In algebra, we use variables to hold a value. Variables typically hold values that are unknown or changing.
                    With the equation x+1=2, x represents a number, in this case 1. At the start, we are unsure of the value of x, hence the use
                    of a variable.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    In computer science, variables are also used to hold a value. Typically, it's something we need
                    to change or manipulate. Unlike in math, we typically know the value of a variable at the start. Therefore,
                    when we create a variable, we usually set it to a value. (If you’re wondering why it’s called a variable, we
                    typically make changes to it that we may not know, hence the uncertainty)
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    To start, let’s focus on numbers, in particular, whole numbers. To make a variable to represent a whole number, use the following
                    format.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        int a=1;
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    “int”, short for integer, is what's known as the type. You’ll soon learn about the other types variables can represent
                    besides numbers! In this case, however, we want an integer(whole number), so we put the “int”. “a” is the name of the
                    variable. Like in math, all variables have names, so we must name our variables. We can name our variables anything as
                    long as the name doesn’t contain any spaces. Finally, we use “=1” to set the value. Like all lines of code, we end with
                    a semicolon “;”.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Now, the computer knows that “a” is an integer with a value of one. It will remember this information throughout the
                    entire code unless we want to change it, which we will discuss later. Another way to declare a variable with an integer type called
                    a with an initial value of a is as follows.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        int a;<br />
                        a=1;
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    You may have noticed that this example looks like the previous example, but spread over two lines. The first line is called
                    a “declaration”. It declares an integer variable named a. However, it doesn’t have any value yet. The second line is called
                    the “assignment”. By using “=1”, we assign the variable a to the value of 1. Make sure you understand what the words “declare”
                    and “assign” means and what parts of the code they are referring to! <br />
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Here's another example.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        int a=1;<br />
                        a=2;
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    We’ve already seen what the first line does. It declares an integer variable "a" and assigns it to a value of 1.
                    Line 2 simply sets the value of a to 2 by using “=2”. By reassigning your variables, you can change their values.
                </p>
                <img src={greatjob} style={{ height: 300, margin: 100 }} alt="Good Job." />
                <h1>Types of Variables</h1>
                <table border="1" style={{ fontSize: 20 }}>
                    <tr>
                        <th>Variable Type</th>
                        <th>Declaration</th>
                        <th>What is it?</th>
                    </tr>
                    <tr>
                        <td>Integer</td>
                        <td><code>int a=1;</code></td>
                        <td>whole numbers <br />
                            Ex: 1, 2, 3, -1, 1000
                        </td>
                    </tr>
                    <tr>
                        <td>String</td>
                        <td><code>String s="yes";</code></td>
                        <td>words, characters, sentences, paragraphs, etc... <br />
                            Ex: “a”, “Mickey Mouse”, “My favorite movie is Cars.”</td>
                    </tr>
                    <tr>
                        <td>Double</td>
                        <td><code>double s=1.2;</code></td>
                        <td>any number (whole and decimal) <br />
                            Ex: 1, 2.03, 5.2352, -4.56</td>
                    </tr>
                    <tr>
                        <td>Boolean</td>
                        <td><code>boolean a=true;</code></td>
                        <td>true or false</td>
                    </tr>
                </table>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Now, let’s talk about other types of data you can store in variables. The table above shows a great summary
                    of them!
                    I'll provide some more details about them down below.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    A double is the same as a decimal number. Therefore, if you want to create a variable and set it to a decimal
                    value, use a double. It can also represent a whole number. However, you usually want to use "int" for whole numbers.
                    Here's another example of us declaring a double.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        double a=1.234;
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Strings can be any size list of characters. Think of them as words, phrases, or a random list of characters
                    put together. Use “ " or double quotes around them. Be sure to capitalize the "S" when declaring a String variable.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        String word= "apple”;<br />
                        String goat= "I am the GOAT at coding yessir!”;
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Booleans are unique in that they can only represent two values: true or false. When we declare a boolean to directly equal true or false,
                    we don't use quotes " " to declare it. We directly assign the value to true or false, as shown in the example.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    While this may initially seem pointless, booleans play an extremely important role in programming. We can combine multiple
                    boolean expressions to form a new boolean expression.
                    We can also use them for conditional statements. You will learn more about booleans and conditionals in Lesson 4.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        boolean t= true;<br />
                        boolean f= false;
                    </code>
                </p>
                <img src={goat} style={{ height: 300, margin: 100 }} alt="GOAT." />
                <p style={{ fontSize: 20 }}>GOAT=Greatest of All Time</p>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Practice Problems</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. Declare a String variable with the name "s". Don't assign it.<br />
                    <input id="name-input" style={{ width: 300 }} />
                    <button onClick={sayHello}>
                        Check
                    </button>
                    <p id="output"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    2. Declare a double variable named "a" with a value of 0.5. <br />
                    <input id="name1-input" style={{ width: 300 }} />
                    <button onClick={fix}>
                        Check
                    </button>
                    <p id="output1"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. Declare a boolean variable named "bool" and set it to false.<br />
                    <input id="name2-input" style={{ width: 300 }} />
                    <button onClick={tf}>
                        Check
                    </button>
                    <p id="output2"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    4. Declare an integer variable named "num" with a value of 5. On the next line, assign it's value to 2.<br />
                    <input id="name3-input" style={{ width: 300 }} /><br />
                    <input id="name4-input" style={{ width: 300 }} />
                    <button onClick={kevin}>
                        Check
                    </button>
                    <p id="output4"></p>
                </p>
                {user.lesson > 2 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} /><br />
                    <Link to="/stickers">Stickers</Link>
                </p>) : (<div></div>)}
                {user.lesson > 2 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}><Link to="/lesson3">Lesson 3</Link><br />

                </p>) : (<div></div>)}

            </div>
        </div>
    )
}

export default Lesson2