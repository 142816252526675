import kidcoding from './Images/kidcoding.jpg'
import cooldisney from './Images/cooldisney.jpg'
import background from './Images/disneybg.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import userService from '../services/user'
import { setU } from '../reducers/userreducer'
import { Link } from "react-router-dom"

const Home2 = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
                .catch(error => {
                    console.log('fail')
                })
        }
        // eslint-disable-next-line
    }, [])

    const user = useSelector(state => state)
    if (user != null) {
        return (
            <div>
                <link rel="stylesheet" href="src/main.css" />
                <div style={{
                    backgroundImage: `url(${background})`,
                    backgroundSize: 'cover', height: 600, padding: 100, backgroundPosition: 'center'
                }}>
                    <h1 style={{ fontSize: 150, color: 'white', textAlign: 'center', margin: 10, position: 'relative', top: -50 }}>CodePetitor</h1>
                </div>
                <div style={{ margin: 100, position: 'relative', top: -50 }}>
                    <h1>Welcome to CodePetitor {user.name}!</h1>
                    {user.lesson >= 10 ? (<h2>Current Progress: Done! Congrats!</h2>) : (<h2>Current Progress: Lesson {user.lesson}, Question {user.question}</h2>)}
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Hello! In this experience, you will learn
                        how to code and solve problems with computers while having fun! How cool!

                        We have a series of topics that will take you through this journey. Each topic will consist of a lesson
                        teaching you all you need to know about each topic. There will be a few practice problems at the end so
                        you can truly master the subject.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Don’t know how to code? Don’t worry! You are a smart
                        kid. We will
                        teach you everything you need to know to
                        be able to beat the challenges. The coding language will be Java.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Every time you beat a level, you win a sticker! The more stickers, the better.
                        The first seven stickers are really cool Disney movies that you should watch! The last two are a suprise!
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Let’s Start! Access <Link to="/lesson1">Lesson 1</Link> whenever you're ready. Good Luck!
                    </p>
                </div>
                <div style={{ position: 'relative', top: -50 }}>
                    <img src={kidcoding} alt="kidcoding" style={{ height: 350, margin: 100, alignSelf: 'flex-start' }} />
                    <img src={cooldisney} alt="cooldisney" style={{ height: 350, margin: 100, alignSelf: 'flex-end' }} />
                </div>
                <footer className="foot">
                    <h1>Made with lots of &#10084;&#65039; by Brian Zhang. Shoutout to my family! &#10084;&#65039;</h1>
                </footer>
                <footer>
                    <p style={{ fontSize: 15 }}>If you have any questions, issues, or suggestions, please reach out to me at itsbz@umich.edu.</p>
                </footer>
            </div>
        )
    }
}



export default Home2