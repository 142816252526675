import Navbar from './components/navbar'
import background from './components/Images/disneybg.jpg'
import Home from './components/homepage'
import Home2 from './components/homepage2'
import About from './components/about'
import Lesson1 from './components/lesson1'
import Lesson2 from './components/lesson2'
import Lesson3 from './components/lesson3'
import Lesson4 from './components/lesson4'
import Lesson5 from './components/lesson5'
import Lesson6 from './components/lesson6'
import Lesson7 from './components/lesson7'
import Lesson8 from './components/lesson8'
import Lesson9 from './components/lesson9'
import Stickers from './components/stickercollection'
import Redirect from './components/null'
import Nextsteps from './components/nextsteps'
import Notification from './components/notification'
import loginService from './services/login'
import userService from './services/user'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { setU } from './reducers/userreducer'
import { Helmet } from "react-helmet";

function App() {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [newname, setnewName] = useState('')
  const [newusername, setnewUsername] = useState('')
  const [newpassword, setnewPassword] = useState('')
  const [remember, setRemember] = useState(false)
  const [login, setLog] = useState(true)
  const [show, setShow] = useState(false)
  const [user, setUser] = useState(null)
  const [error, setErrorMessage] = useState(null)

  useEffect(() => {
    const loggedUserJSON = window.localStorage.getItem('loggedUser')
    if (loggedUserJSON) {
      const user = JSON.parse(loggedUserJSON)
      userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
      setUser(user)
    }
    // eslint-disable-next-line
  }, [])


  const dispatch = useDispatch()
  const handleLogin = async (event) => {
    event.preventDefault()
    try {
      const user = await loginService.login({
        username, password,
      })
      if (remember) {
        window.localStorage.setItem(
          'loggedUser', JSON.stringify(user)
        )
      }
      setUser(user)
      userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
      setUsername('')
      setPassword('')
      setRemember(false)
    } catch (error) {
      setErrorMessage('Wrong Credentials')
      setTimeout(() => {
        setErrorMessage(null)
      }, 5000)
    }
  }

  const handleSignUp = async (event) => {
    event.preventDefault()
    try {
      await userService.signUp({
        newusername, newname, newpassword
      })
      const user = await loginService.login({
        username: newusername, password: newpassword,
      })
      if (remember) {
        window.localStorage.setItem(
          'loggedUser', JSON.stringify(user)
        )
      }
      setUser(user)
      userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
      setnewName('')
      setnewPassword('')
      setnewUsername('')
      setRemember(false)
    }
    catch (error) {
      if (error.response.data.error === 'too short') {
        setErrorMessage('Credentials are too short')
        setTimeout(() => {
          setErrorMessage(null)
        }, 5000)

      }
      else {
        setErrorMessage('Username is not unique')
        setTimeout(() => {
          setErrorMessage(null)
        }, 5000)

      }
    }
  }

  const logout = () => {
    window.localStorage.clear()
    dispatch(setU(null))
    setUser(null);
  }

  const handleChange = (event) => {
    event.preventDefault()
    setLog(!login)
  }

  const showLog = (event) => {
    event.preventDefault()
    setShow(true)
  }


  const hideLog = (event) => {
    event.preventDefault()
    setShow(false)
  }

  const loginForm = () => (
    <div className="wrapper">
      <Helmet>
        <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
        <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
      </Helmet>
      <span className="icon-close" onClick={hideLog}>
        <ion-icon name="close"></ion-icon>
      </span>
      {/* LOGIN FORM */}
      <div className="form-box login">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div className="input-box">
            <span className="icon">
              <ion-icon name="person-outline">
              </ion-icon>
            </span>
            <input
              type="text" required
              value={username}
              onChange={({ target }) => setUsername(target.value)}
            />
            <label>Username</label>
          </div>
          <div className="input-box">
            <span className="icon">
              <ion-icon name="lock-closed">
              </ion-icon>
            </span>
            <input type="password" required value={password} name="Password" onChange={({ target }) => setPassword(target.value)} />
            <label>Password</label>
          </div>
          <div className="remember-forgot">
            <input type="checkbox"
              value={remember}
              onChange={({ target }) => setRemember(target.value)} />
            <label>Remember me</label>
          </div>
          <button className="btn" type="submit">Login</button>
          <div className="login-register">
            <p>Don't have an account? <br />
              <button className="btn2" type="submit" onClick={handleChange}>Register</button>
            </p>
          </div>
        </form>
      </div>
    </div>
  )

  const signUp = () => (
    <div className="wrapper" style={{ height: 520 }}>
      <Helmet>
        <script type="module" src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.esm.js"></script>
        <script nomodule src="https://unpkg.com/ionicons@7.1.0/dist/ionicons/ionicons.js"></script>
      </Helmet>
      <span className="icon-close" onClick={hideLog}>
        <ion-icon name="close"></ion-icon>
      </span>
      {/* SIGN UP FORM */}
      <div className="form-box register">
        <h2>Sign Up</h2>
        <form onSubmit={handleSignUp}>
          <div className="input-box">
            <span className="icon">
              <ion-icon name="person-circle-outline">
              </ion-icon>
            </span>
            <input
              type="text" required
              value={newname}
              onChange={({ target }) => setnewName(target.value)}
            />
            <label>Name</label>
          </div>
          <div className="input-box">
            <span className="icon">
              <ion-icon name="person-outline">
              </ion-icon>
            </span>
            <input
              type="text" required
              value={newusername}
              onChange={({ target }) => setnewUsername(target.value)}
            />
            <label>Username</label>
          </div>
          <div className="input-box">
            <span className="icon">
              <ion-icon name="lock-closed">
              </ion-icon>
            </span>
            <input type="password" required value={newpassword} name="Password" onChange={({ target }) => setnewPassword(target.value)} />
            <label>Password</label>
          </div>
          <div className="remember-forgot">
            <input type="checkbox"
              value={remember}
              onChange={({ target }) => setRemember(target.value)} />
            <label>Remember me</label>
          </div>
          <button className="btn" type="submit">Register</button>
          <div className="login-register">
            <p>Already have an account? <br />
              <button className="btn2" type="submit" onClick={handleChange}>Log In</button>
            </p>
          </div>
        </form>
      </div>
    </div>
  )

  const form = () => (
    <div>
      {show ? (<div>{loginForm()}</div>) : <div></div>}
    </div>
  )


  const sform = () => (
    <div>
      {show ? (<div>{signUp()}</div>) : <div></div>}
    </div>
  )


  if (user === null) {
    return (
      <>
        <Navbar logout={showLog} text={"Log in"} />
        <div className="container">
          <Routes>
            <Route path="/" element={<div>
              <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover', height: 600, padding: 100, backgroundPosition: 'center'
              }}>
                <h1 style={{ fontSize: 150, color: 'white', textAlign: 'center', margin: 10, position: 'relative', top: -50 }}>CodePetitor</h1>
                <div style={{
                  display: "flex", alignItems: "center", justifyContent: "center",
                  height: "100%"
                }}>
                  <div style={{ position: 'relative', top: -175 }}>
                    {login ? (<div>{form()}</div>) : (<div>{sform()}</div>)}
                    <Notification message={error} />
                  </div>
                </div>
              </div>
              <div style={{ textAlign: 'center' }}>
                <p style={{ fontSize: 30 }}><em>Introduction to Competitive Coding for Kids of any Age!!</em></p>
                <p style={{ fontSize: 30 }}><em>Learn to Code, Solve Problems, Have Fun!</em></p>
              </div>
              <Home />
            </div>} />
            <Route path="/about" element={<About />} />
            <Route path="/lesson1" element={<Redirect />} />
            <Route path="/lesson2" element={<Redirect />} />
            <Route path="/lesson3" element={<Redirect />} />
            <Route path="/lesson4" element={<Redirect />} />
            <Route path="/lesson5" element={<Redirect />} />
            <Route path="/lesson6" element={<Redirect />} />
            <Route path="/lesson7" element={<Redirect />} />
            <Route path="/lesson8" element={<Redirect />} />
            <Route path="/lesson9" element={<Redirect />} />
            <Route path="/stickers" element={<Redirect />} />
            <Route path="/nextsteps" element={<Redirect />} />
          </Routes>
        </div>
      </>
    )
  }

  return (
    <>
      <Navbar logout={logout} text={"Logout"} />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home2 />} />
          <Route path="/about" element={<About />} />
          <Route path="/lesson1" element={<Lesson1 />} />
          <Route path="/lesson2" element={<Lesson2 />} />
          <Route path="/lesson3" element={<Lesson3 />} />
          <Route path="/lesson4" element={<Lesson4 />} />
          <Route path="/lesson5" element={<Lesson5 />} />
          <Route path="/lesson6" element={<Lesson6 />} />
          <Route path="/lesson7" element={<Lesson7 />} />
          <Route path="/lesson8" element={<Lesson8 />} />
          <Route path="/lesson9" element={<Lesson9 />} />
          <Route path="/stickers" element={<Stickers />} />
          <Route path="/nextsteps" element={<Nextsteps />} />
        </Routes>
      </div>
    </>
  )
}

export default App;
