import { Helmet } from "react-helmet";
import background from './Background/Lesson8.png'
import bigbrain from './images2/bigbrain.jpg'
import userService from '../services/user'
import sticker from './sticker/disneyland.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'

const Lesson8 = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])
    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    // eslint-disable-next-line
    if (user != null && user.lesson < 8) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    const p1 = () => {
        let name = document.getElementById('name1-input').value;
        let outputElement = document.getElementById('output1');
        if (name === "completed" || name === "Completed") {
            if (user.lesson === 8 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            }
            else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p2 = () => {
        let name = document.getElementById('name2-input').value;
        let outputElement = document.getElementById('output2');
        if (user.lesson === 8 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 8 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            } else {
                outputElement.innerText = 'Already Completed.'

            }
        }
    }
    const p3 = () => {
        let name = document.getElementById('name3-input').value;
        let outputElement = document.getElementById('output3');
        if (user.lesson === 8 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 8 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            }
            else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p4 = () => {
        let name = document.getElementById('name4-input').value;
        let outputElement = document.getElementById('output4');
        if (user.lesson === 8 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 8 && user.question === 4) {
                const params = { id: user.id, lesson: false, number: 5 }
                userService.increment(params)
                dispatch(incrementQuestion())
                outputElement.innerText = 'Nice Job! Progress saved.'
            } else {
                outputElement.innerText = 'Already Completed.'
            }
        }
    }
    const p5 = () => {
        let name = document.getElementById('name5-input').value;
        let outputElement = document.getElementById('output5');
        if (user.lesson === 8 && user.question <= 4) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === "completed" || name === "Completed") {
            if (user.lesson === 8 && user.question === 5) {
                const params = { id: user.id, lesson: true, number: 9 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 8! Check out your new sticker and get started with Lesson 9 when you are ready!'
        }
    }
    return (
        <div>
            <Helmet>
                <style>{'body {background-color: rgb(144, 144, 255); color: black}'}</style>
                <script src="https://www.jdoodle.com/assets/jdoodle-pym.min.js" type="text/javascript"></script>
            </Helmet>
            <div style={{ height: 10 }}></div>
            <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover', height: 550, padding: 100, backgroundPosition: 'center'
            }}>
            </div>
            <div>
                <p style={{ fontSize: 30, color: 'black', textAlign: 'center' }}><em>Problems(Medium)</em></p>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Round 2</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Time for some harder problems! Same drill as last time. GOOD LUCK!
                </p>
            </div>
            <img src={bigbrain} alt="Hello" style={{ height: 375, margin: 100 }} /><br />
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. Given an int array arr, return true if it contains the value 7 in it.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uW5?rw=1&arg=0&stdin=0" title="lesson8-problem1" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" in the box below to confirm the completion of the problem! (Make sure the confirmation pops up).</p>
                <input id="name1-input" style={{ width: 300 }} /><br />
                <button onClick={p1}>
                    Submit
                </button>
                <p id="output1"></p>
            </div>

            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    2. Given two int arrays a and b, create a new array with a size of 2, with the first element being the first element of a and the second element
                    being the first element in b.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uWc?rw=1&arg=0&stdin=0" title="lesson8-problem2" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name2-input" style={{ width: 300 }} /><br />
                <button onClick={p2}>
                    Submit
                </button>
                <p id="output2"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. Given a number n, return true if it's a multiple of 4 or 7 but not both.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uWs?rw=1&arg=0&stdin=0" title="lesson8-problem3" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name3-input" style={{ width: 300 }} /><br />
                <button onClick={p3}>
                    Submit
                </button>
                <p id="output3"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    4. Given an array of doubles, arr, find the max value of the square of an element and return the squared value.<br />
                    Be careful! -7 squared is bigger than 6 squared even though -7 is smaller than six.<br />
                    In other words, square each element and find the largest one.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uWV?rw=1&arg=0&stdin=0" title="lesson8-problem4" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name4-input" style={{ width: 300 }} /><br />
                <button onClick={p4}>
                    Submit
                </button>
                <p id="output4"></p>
            </div>
            <div style={{ margin: 100 }}>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    5. Given three ints a, b, and c, return the sum of the set of unique integers. This means that if a number appears more than 1
                    time, we only add it once to the sum.<br />
                    EX: a=1, b=1, c=2. The set of unique integers is {"{1,2}"}. Thus, our answer is 3. Notice we only added 1 ONCE, even though it appears twice.
                </p>
                <iframe src="https://www.jdoodle.com/embed/v0/5uWJ?rw=1&arg=0&stdin=0" title="lesson8-problem5" width={1200} height={700}></iframe>
            </div>
            <div style={{ margin: 100, lineheight: 2.0, fontSize: 20 }}>
                <p>Type the word "completed" and submit when solved.</p>
                <input id="name5-input" style={{ width: 300 }} /><br />
                <button onClick={p5}>
                    Submit
                </button>
                <p id="output5"></p>
            </div>
            <div style={{ margin: 100 }}>
                {user.lesson > 8 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} /><br />
                    <Link to="/stickers">Stickers</Link>
                </p>) : (<div></div>)}
                {user.lesson > 8 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}><Link to="/lesson9">Lesson 9</Link>
                </p>) : (<div></div>)}
            </div>

        </div>
    )
}

export default Lesson8