import { Helmet } from "react-helmet";
import background from './Background/Lesson6.png'
import lol from './images2/lol.jpg'
import userService from '../services/user'
import sticker from './sticker/lionking.jpg'
import { useDispatch, useSelector } from 'react-redux'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'
import ezmoney from './images2/ezmoney.jpg'

const Lesson6 = () => {
    const p1 = () => {
        let inputElement = document.getElementById('name-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output');
        if (name === '5') {
            if (user.lesson === 6 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p2 = () => {
        let inputElement = document.getElementById('name1-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output1');
        if (user.lesson === 6 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'yes' || name === 'Yes' || name === 'YES') {
            if (user.lesson === 6 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p5 = () => {
        let inputElement1 = document.getElementById('name2-input');
        let name1 = inputElement1.value;
        name1 = name1.replace(/\s/g, '');
        const a = (name1 === 'while(c<20){')
        let inputElement2 = document.getElementById('name3-input');
        let name2 = inputElement2.value;
        name2 = name2.replace(/\s/g, '');
        const b = (name2 === '++c;' || name2 === 'c++;' || name2 === 'c=c+1;' || name2 === 'c=1+c;')
        let inputElement = document.getElementById('name4-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        const c = name === '}'
        let outputElement = document.getElementById('output4');
        if (user.lesson === 6 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (a && b && c) {
            if (user.lesson === 6 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again! Hint: You already wrote the {"{"} on the first line. What do you need on the last line?'
        }
    }
    const p9 = () => {
        let inputElement1 = document.getElementById('name5-input');
        let name1 = inputElement1.value;
        name1 = name1.replace(/\s/g, '');
        const a = (name1 === 'inti=0;');
        let inputElement2 = document.getElementById('name6-input');
        let name2 = inputElement2.value;
        name2 = name2.replace(/\s/g, '');
        const b = (name2 === 'while(i<10){' || 'while(i<arr.length)')
        let inputElement3 = document.getElementById('name7-input');
        let name3 = inputElement3.value;
        name3 = name3.replace(/\s/g, '');
        const c = (name3 === 'arr[i]=i;');
        let inputElement = document.getElementById('name8-input');
        let name = inputElement.value;
        const d = (name === '}');
        let inputElement4 = document.getElementById('name9-input');
        let name4 = inputElement4.value;
        name4 = name4.replace(/\s/g, '');
        const e = (name4 === 'i++;');
        let outputElement = document.getElementById('output8');
        if (user.lesson === 6 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (a && b && c && d && e) {
            if (user.lesson === 6 && user.question === 4) {
                const params = { id: user.id, lesson: true, number: 7 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 6! Check out your new sticker and get started with Lesson 7 when you are ready!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])

    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    if (user.lesson < 6) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    return (
        <div>
            <Helmet>
                <style>{'body {background-color: limegreen; color: black}'}</style>
            </Helmet>
            <div style={{ height: 25 }}></div>
            <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover', height: 650, padding: 100, backgroundPosition: 'center'
            }}>
            </div>
            <div>
                <p style={{ fontSize: 30, color: 'black', textAlign: 'center' }}><em>Loops</em></p>
            </div>
            <div style={{ margin: 100 }}></div>
            <div style={{ margin: 100 }}>
                <h1>Introduction</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    In computer programming, a loop is a sequence of instructions that
                    is continually repeated until a certain condition is
                    reached.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Assume we have an array of integers. Let’s say it’s a big one, size 1000.
                    We want to fill it up all with 1s. Are you excited to write 1000 lines of code to fill it up one by one?
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Almost everyone will say no. If you agree, you will love this lesson about loops. Loops are simple ways
                    to tell the computer to do something over and over again. This way, we don’t have to write 1000 lines of
                    code. We tell the computer to automatically stop by telling it to check a boolean(eg., a condition is met). If it’s false,
                    the computer stops the loop. In a sense, this aspect of the loop is similar to an if statement, as a boolean value tells the computer whether
                    or not to run a command.

                    That might’ve been a bit confusing. I know. Let me try to help you out as much as possible. Today, we will be
                    introducing two types of loops today. The for-loop and the while-loop. I will provide as many explanations
                    and examples as possible to help you learn this concept! It’s a hard one, but we will get through it!
                </p>
                <img src={lol} style={{ height: 400, margin: 100 }} alt="GOAT." />
                <h1>For-Loops</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Let’s look at an example of a for-loop. Try to guess what you think it does!<br />
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        <pre>
                            {"int[] arr=new int[1000];"}<br />
                            {"for(int i=0;i<1000;i++){"}<br />
                            {"  arr[i]=1;"}<br />
                            {"}"}     <br />
                        </pre>
                    </code>
                    What we are doing here is first creating integer i, which we start at 0. We do this within the {"()"} after
                    the “for”. Then, we check if i is less than arr.length(1000 in this example) (the
                    condition for the loop to run), which is written after a semicolon after "int i=0". If
                    this is true, we can run the loop. In the body of the loop, we simply set {"arr[i]"} to equal 1. “i” is 0 right now, so arr[0]=1. After the body of the loop finishes running,
                    our code runs i++, which we put after the second “;” after the boolean statement. This is the same thing as
                    i=i+1. All we are doing here is incrementing i so we can set the next element of the array to 1.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Eventually, i will equal 999. We set arr[999]=1, then add 1 to i. Now i is equal to 1000. We check i{"<"}1000.
                    However, this is now false. Thus, the loop ends because of this. Note that we don't include a semicolon
                    after the line with "for" and the closing bracket {"}"}.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    To summarize, there are four parts to a for-loop. The first part declares a new variable, such as int
                    i=0;. This happens before the loop even starts, and only happens once. The second
                    part happens at the start each time the loop runs, where it checks a boolean such as i{"<"}1000. If this
                    boolean is true, the loop continues to run. If it isn’t, the loop ends. The third part of the for-loop is
                    to update the index for the variable after the body of the loop runs each time. For example, i is
                    increased by 1 through statement i++ so
                    the loop will go through the whole array. The last part is the body of the loop, where it performs what the user
                    intends to do, setting the array to 1! In the
                    example, we set arr[i]=1;
                </p>
                <img src={ezmoney} alt="Hello" style={{ height: 375, margin: 100 }} />
                <h1>While-Loops</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    The while-loop is another popular loop. I believe it’s a lot simpler than a for-loop.
                    However, you will see more for-loops than while-loops, which is why I introduced the for-loop
                    first. Yet, the while-loop is extremely important and useful!
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Like a for-loop or an if-statement, the while-loop depends on a boolean to control its execution. In fact, it depends
                    solely on a boolean. When the boolean is true, the loop will run. Once the boolean isn’t
                    true anymore, the loop stops running. Check out the following example.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        <pre>
                            boolean a=true;<br />
                            while(a){"{"}<br />
                            {"  "}System.out.println(1);<br />
                            {"}"}<br />
                        </pre>
                    </code>
                    Here, the while-loop checks if the boolean within the parentheses is true. In this case,
                    since a is set to be true, it will always evaluate to true. Thus, the loop will always run.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    What’s wrong with that while-loop? Can you spot it?
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Well, the loop never ends since the boolean will always evaluate to true. Thus, this will
                    run an infinite amount of times. This is not okay. The computer will crash.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Let’s give an example of a while-loop that does work.<br />
                    <code>
                        int[] arr=new int[1000];
                        int i=0;<br />
                        while(i{"<"}1000){"{"}<br />
                        {"  "}i++; <br />
                        {"  "}arr[i]=1;<br />
                        {"}"}<br />
                    </code>
                    Here, “i” starts at 0. We then check to see if i{"<"}1000, which is true. Since it’s true, we run
                    our code inside the loop, which adds 1 to i.
                    Meanwhile, it also set arr[i] to 1. It repeats this process until i is equal to
                    1000, where then i{"<"}1000 is false. Now the loop ends and the computer moves on to the next
                    lines of code.
                </p>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Practice Problems</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. Complete the code so that the for-loop runs 5 times. What goes in the "_"?<br />
                    for(int i=0;i{"<"}_;i++)<br />
                    <input id="name-input" style={{ width: 300 }} />
                    <button onClick={p1}>
                        Check
                    </button>
                    <p id="output"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    2. Will this code infinite loop? Will the loop fail to terminate? (yes or no)<br />
                    <code>
                        <pre>
                            int i=10;<br />
                            while{"(i>0){"}<br />
                            {"  "}i++;<br />
                            {"}"}<br />
                        </pre>
                    </code>
                    <input id="name1-input" style={{ width: 300 }} />
                    <button onClick={p2}>
                        Check
                    </button>
                    <p id="output1"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. You are given an integer c that's set to zero. Write a while-loop that adds one to c each time and ends
                    when c is greater than or equal to 20.<br />
                    <pre>
                        <input id="name2-input" style={{ width: 300 }} /><br />
                        {"  "}<input id="name3-input" style={{ width: 300 }} /><br />
                        <input id="name4-input" style={{ width: 300 }} /><br />
                        <button onClick={p5}>
                            Check
                        </button>
                    </pre>
                    <p id="output4"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    4. Using a while-loop, set each element of an integer array named "arr" size 10 to its corresponding index.<br />
                    Step 1: Create an index variable named i. (Think about what to set it equal to initially.)<br />
                    <input id="name5-input" style={{ width: 300 }} /><br />
                    Step 2: Create the while-loop. Include brackets.<br />
                    <pre>
                        <input id="name6-input" style={{ width: 300 }} /><br />
                        {"  "} <input id="name7-input" style={{ width: 300 }} /><br />
                        {"  "} <input id="name9-input" style={{ width: 300 }} /><br />
                        <input id="name8-input" style={{ width: 300 }} /><br />
                        <button onClick={p9}>
                            Check
                        </button>
                    </pre>
                    <p id="output8"></p>
                </p>
                {user.lesson > 6 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} /><br />
                    <Link to="/stickers">Stickers</Link>
                </p>) : (<div></div>)}
                {user.lesson > 6 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}><Link to="/lesson7">Lesson 7</Link>
                </p>) : (<div></div>)}

            </div>
        </div>
    )
}

export default Lesson6