import { Link, useMatch, useResolvedPath } from "react-router-dom"

const Navbar = ({ logout, text }) => {
    return (
        <div>
            <nav className="nav">
                <ul>
                    <Link to="/" className="site-title" style={{ fontSize: 20 }}>
                        CodePetitor
                    </Link>
                    <CustomLink to="/">Home</CustomLink>
                    <CustomLink to="/lesson1">Lesson1</CustomLink>
                    <CustomLink to="/lesson2">Lesson2</CustomLink>
                    <CustomLink to="/lesson3">Lesson3</CustomLink>
                    <CustomLink to="/lesson4">Lesson4</CustomLink>
                    <CustomLink to="/lesson5">Lesson5</CustomLink>
                    <CustomLink to="/lesson6">Lesson6</CustomLink>
                    <CustomLink to="/lesson7">Lesson7</CustomLink>
                    <CustomLink to="/lesson8">Lesson8</CustomLink>
                    <CustomLink to="/lesson9">Lesson9</CustomLink>
                    <CustomLink to="/nextsteps">Next Steps</CustomLink>
                    <CustomLink to="/stickers">Stickers</CustomLink>
                    <CustomLink to="/about">About</CustomLink>
                </ul>
                <Button1 log={logout} text={text} className="butt"></Button1>
            </nav>
        </div>
    )
}
function Button1({ log, text }) {
    return (<button onClick={log} className='bstyle'>{text}</button>)
}

function CustomLink({ to, children, ...props }) {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    )
}

export default Navbar