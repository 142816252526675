import { Helmet } from "react-helmet";
import arr from './images2/arr.png'
import access from './images2/access.jpg'
import codingisfun from './images2/codingisfun.jpg'
import background from './Background/Lesson5.png'
import userService from '../services/user'
import sticker from './sticker/toystory.webp'
import { useDispatch, useSelector } from 'react-redux'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'

const Lesson5 = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])
    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    if (user.lesson < 5) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    const p1 = () => {
        let inputElement = document.getElementById('name-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output');
        name = name.replace(/\s/g, '');
        if (name === 'String[]s=newString[12];') {
            if (user.lesson === 5 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p2 = () => {
        let inputElement = document.getElementById('name1-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output1');
        if (user.lesson === 5 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === '4') {
            if (user.lesson === 5 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p5 = () => {
        let inputElement = document.getElementById('name4-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        let inputElement1 = document.getElementById('name2-input');
        let name1 = inputElement1.value;
        name1 = name1.replace(/\s/g, '');
        const a = (name1 === 'int[]a=newint[10];')
        let inputElement2 = document.getElementById('name3-input');
        let name2 = inputElement2.value;
        name2 = name2.replace(/\s/g, '');
        const b = (name2 === 'a[0]=10;');
        let outputElement = document.getElementById('output4');
        if (user.lesson === 5 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'a[5]=4;' && a && b) {
            if (user.lesson === 5 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }

    const p8 = () => {
        let inputElement1 = document.getElementById('name5-input');
        let name1 = inputElement1.value;
        name1 = name1.replace(/\s/g, '');
        let inputElement2 = document.getElementById('name6-input');
        let name2 = inputElement2.value;
        name2 = name2.replace(/\s/g, '');
        let inputElement = document.getElementById('name7-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        let outputElement = document.getElementById('output7');
        const a = (name1 === 'String[]sanders=newString[5];')
        const b = (name2 === 'sanders[1]="beans";')
        if (user.lesson === 5 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (a && b && name === 'System.out.println(sanders[1]);') {
            if (user.lesson === 5 && user.question === 4) {
                const params = { id: user.id, lesson: false, number: 5 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p12 = () => {
        let inputElement = document.getElementById('name11-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        let inputElement1 = document.getElementById('name8-input');
        let name1 = inputElement1.value;
        name1 = name1.replace(/\s/g, '');
        let inputElement2 = document.getElementById('name9-input');
        let name2 = inputElement2.value;
        name2 = name2.replace(/\s/g, '');
        let inputElement3 = document.getElementById('name10-input');
        let name3 = inputElement3.value;
        name3 = name3.replace(/\s/g, '');
        const a = (name1 === 'int[]yo=newint[4];');
        const b = (name2 === 'yo[0]=1;');
        const c = (name3 === 'yo[1]=2;')
        const d = (name === 'yo[2]=yo[1]+yo[0];' || name === 'yo[2]=yo[0]+yo[1];');
        let outputElement = document.getElementById('output11');
        if (user.lesson === 5 && user.question <= 4) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (a && b && c && d) {
            if (user.lesson === 5 && user.question === 5) {
                const params = { id: user.id, lesson: true, number: 6 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 5! Check out your new sticker and get started with Lesson 6 when you are ready!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }

    return (
        <div>
            <Helmet>
                <style>{'body {background-color: black; color: white}'}</style>
            </Helmet>
            <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover', height: 550, padding: 200, backgroundPosition: 'center'
            }}>
            </div>
            <div>
                <p style={{ fontSize: 30, color: 'white', textAlign: 'center' }}><em>Arrays</em></p>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Introduction to Arrays </h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Many times, we want to store multiple values instead of just one. That’s when an array comes in handy.
                    Arrays allow us to store many many numbers, Strings, or booleans in one collection
                    with one variable instead of one at a time with many variables. All
                    of these elements are stored in the same array! Let’s give an example of an array and how you can make one!
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        int[] arr=new int[10];<br />
                    </code>
                    In the line of code above, we are creating an array of 10 integers. The name of the array is called arr.
                    <img src={arr} style={{ height: 100, margin: 100 }} alt="GOAT." />
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    The table above represents the newly created array, arr. It’s simply a Java type that can store 10 integers. Currently, nothing’s in
                    it
                    because we never set it to have anything. We will show you how to set elements, or spaces in the picture,
                    to
                    equal certain values. Keep in mind arr is a variable name that refers to the array. Also, the size of this
                    array is fixed(10 in this example). We can't change the size once it's declared.
                </p>
                <img src={access} style={{ height: 400, margin: 100 }} alt="GOAT." />

                <h1>Indexing and Accessing </h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    To access each element in the array, we must use a special call. As you can see in the drawing of the array
                    from the previous section, I labeled each box with a number beneath. The number represents the index of the
                    array. In an array, the first element has an index of 0. This may sound weird. Why isn’t the first element
                    indexed as 1? Well, computers are weird and I will save the explanation for another day. For
                    now, it’s a good idea to accept this fact because you will encounter many situations where the default will be
                    zero in programming. From there, we count by one each time, so
                    the second element is indexed with 1, the third element is indexed with 2, and so on. If we wanted to see the 5th
                    element in the array, we would look for an index of 4. </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Now comes the question of how to change/view the value of an element of an array. Let's check out the code
                    down below.<br />
                    <code>
                        arr[0]=1;<br />
                    </code>
                    This changes the value of the 0th index, or the 1st element, to 1. Be careful. Don't try to change an
                    element that doesn't exist. If the size of the array is 10, don't try to access index 10 or anything higher
                    because such an element simply does not exist.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    If we want to print the value at a certain index, we can do so by calling print(arr[index]); <br />
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>

                        <code>
                            arr[4]=10;<br />
                            System.out.println(arr[4]);<br />
                        </code>
                    </p>
                    This will print out 10, the value of arr[4].
                </p>

                <h1>Extra Topics</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    While we focused on int arrays for the first few, arrays can be of any type. int, double, boolean, and
                    String all work! I put some examples down below.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        String[] john=new String[10];<br />
                        boolean[] bigbools=new boolean[5];<br />
                        double[] ppg=new double[13];<br />
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    We can also create arrays as follows. <br />
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>int[] j={"{1,3,4,5};"}</code><br />
                    </p>
                    This allows us to set all the elements at the start instead of going through the array one by one.
                    Here, we directly write out the elements of the array. It’s efficient if we already know what we
                    want to put inside the array. The statement gives you an integer array, j, with a first element of 1, a second element of 3,
                    a third element of 4, and a fourth element of 5. The statement also
                    implicitly set the array size to be 4. Be sure to study how we did this so you can replicate it later.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Let’s continue with the previous array j we just created. We can set elements of the array using the
                    values of the other elements. For example:
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>
                            j[3]=j[1]+j[2];<br />
                        </code>
                    </p>
                    This changes j[3] equal to 7, as j[1]+j[2]=7.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Finally, I'm going to cover the size of an array. Let’s say you forget the size or don’t know the size of
                    an array. To get the size, call arr.length. Replace arr with the name of the array of interest.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    For example: <br />
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>
                            int[] random=new int[4];<br />
                            int size=random.length;<br />
                        </code>
                    </p>
                    size is now equal to 4, as 4 is the size of random.
                </p>
                <img src={codingisfun} style={{ height: 400, margin: 100 }} alt="GOAT." />
                <h1>Practice Problems</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. Declare a String array with a size of 12. Call it "s". <br />
                    <input id="name-input" style={{ width: 300 }} />
                    <button onClick={p1}>
                        Check
                    </button>
                    <p id="output"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    2. What index is the 5th element of an array? (Enter single number)<br />
                    <input id="name1-input" style={{ width: 300 }} />
                    <button onClick={p2}>
                        Check
                    </button>
                    <p id="output1"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. First, create an integer array size of 10. Name it "a". Then, set the first element to 10. Finally, set the element of INDEX 5 to
                    4. <br />
                    <input id="name2-input" style={{ width: 300 }} /><br />
                    <input id="name3-input" style={{ width: 300 }} /><br />
                    <input id="name4-input" style={{ width: 300 }} />
                    <button onClick={p5}>
                        Check
                    </button>
                    <p id="output4"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    4. Create a String array named "sanders" with a size of 5. Set the second element of sanders equal to
                    “beans”(line 2). Then, print the second element of the array(line 3).<br />
                    <input id="name5-input" style={{ width: 300 }} /><br />
                    <input id="name6-input" style={{ width: 300 }} /><br />
                    <input id="name7-input" style={{ width: 300 }} />
                    <button onClick={p8}>
                        Check
                    </button>
                    <p id="output7"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    5. Create an integer array size of 4 named "yo". First, set the first element to be equal to 1. Then, set the
                    second element equal to 2. Finally, set the 3rd element to equal the sum of the previous two elements. Do this by
                    accessing elements of the array. Don't simply set it to equal 3.<br />
                    <input id="name8-input" style={{ width: 300 }} /><br />
                    <input id="name9-input" style={{ width: 300 }} /><br />
                    <input id="name10-input" style={{ width: 300 }} /><br />
                    <input id="name11-input" style={{ width: 300 }} />
                    <button onClick={p12}>
                        Check
                    </button>
                    <p id="output11"></p>
                </p>
                {user.lesson > 5 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} /><br />
                    <Link to="/stickers" style={{ color: 'white' }}>Stickers</Link>
                </p>) : (<div></div>)}
                {user.lesson > 5 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}><Link to="/lesson6" style={{ color: 'white' }}>Lesson 6</Link>

                </p>) : (<div></div>)}
            </div>
        </div>
    )
}

export default Lesson5