import kidoncomp from './Images/kidoncomp.jpg'
import aplus from './Images/a+.webp'
import resume from './Images/resume.jpg'
import interview from './Images/interview.jpg'
import coolkid from './Images/coolkid.jpg'
import React from 'react';

const About = () => {
    return (
        <div>
            <div style={{ margin: 100 }}>
                <p className="times">
                    <img className="left" src={kidoncomp} style={{ height: 400, margin: 100 }} alt="Happy Kid." />
                    <h1>Dear Parents and Instructors </h1>
                    Thank you for visiting CodePetitor! I’m grateful for your interest. I would like to take this time
                    to tell my story. In the process, I will explain why I think it’s important that your kids start
                    learning competitive programming as soon as possible.
                </p>
                <p className="times">
                    Like many of you, my parents cared a lot about my math abilities, giving me extra problems outside
                    of regular school hours. I was always pretty good at math. Yet, I always knew there would be a time
                    when I would have to expand into other fields, such as coding. As it turned out, my start in coding
                    was with competitive coding, as it was the smoothest transition for me. However, it wasn’t easy. Making
                    the switch at 15, I struggled with the resources provided. A lot of the resources weren’t tailored for
                    people like me, who didn’t know how to code and were extremely new to the field of CS. Yet, I persevered
                    and eventually had a successful competitive coding experience.
                </p>
                <p className="times">
                    Today, I recognize the benefits of competitive coding. As a computer science major at the University of Michigan,
                    I understand that competitive coding does much more than just helping with college applications. While it's hard to
                    understate the benefit it has on college apps, I will go over even more far reaching benefits.
                </p>
            </div>
            <div style={{ height: 25 }}>&nbsp;</div>
            <div style={{ margin: 100 }}>
                <img src={aplus} className="left" style={{ height: 350, margin: 100 }} alt="A+." />
                <h1>Success in Courses </h1>
                <p className="times">
                    A background in competitive coding helps one understand computer science concepts and makes course content more
                    learnable and engaging. The computer science curriculum is often rigorous with little room for error. Thus, having
                    that slight edge makes a huge difference. Your kid will be able to master the content, earn amazing grades, and feel
                    confident as they start taking more challenging courses. Furthermore, the critical thinking, problem-solving, and
                    coding skills they will gain throughout competitive coding will benefit them in any class, regardless of the topic.
                </p>
            </div>
            <div style={{ height: 25 }}>&nbsp;</div>
            <div style={{ margin: 100 }}>
                <img src={resume} className="right" style={{ height: 350, margin: 100 }} alt="resume" />
                <h1>Resume/Opportunities</h1>
                <p className="times">
                    Participating in competitive coding brings in a lot of opportunities for them to build their resume. Adding
                    one's accomplishments within this field will allow one to stand out from the rest of the crowd. Furthermore, they
                    will have more confidence to take advantage of other opportunities such as clubs, research, and projects. Success in competitive coding
                    will allow for success in so many other endeavors within the field of computer science.
                </p>
            </div>
            <div style={{ height: 25 }}>&nbsp;</div>
            <div style={{ margin: 100 }}>
                <img src={interview} className="left" style={{ height: 350, margin: 100 }} alt="interview" />
                <h1>Interviews</h1>
                <p className="times">
                    Now the job offers will start coming in left and right! But first, you must pass a coding interview! Thankfully,
                    there's a large intersection between coding interviews and competitive coding. You will ace every interview easily
                    if you develop a strong foundation in competitive coding. This applies to almost any software engineering
                    position, but also to positions in academia and more! </p>
            </div>
            <div style={{ height: 25 }}>&nbsp;</div>
            <div style={{ margin: 100 }}>
                <img src={coolkid} className="right" style={{ height: 400, margin: 100 }} alt="coolkid" />
                <h1>Conclusion</h1>
                <p className="times">
                    Now that you know all the benefits, how do we get started? There are limited resources on competitive coding, and
                    those available tend to be intimidating and not beginner friendly. That's why I made CODEPETITOR. I believe that
                    competitive coding should not be intimidating. It should be something that should be introduced to talented youth as
                    soon as possible, especially if they are interested in STEM and TECH. One of my biggest regrets was not learning to code
                    early enough. I felt like if I started during middle school, I would not only have more success in the field of competitive
                    coding but also have been able to expand my interest in computer science beyond just problem-solving. If you have the
                    same goals for your kids, I hope you won’t make my mistake and get them started as soon as possible. After completing this intro-level
                    course, make sure your student continues to persevere. Continue to learn how to code, continue to problem solve, and continue to
                    compete. I will provide resources at the end of the course that will help guide you and your kid through the next steps. Also,
                    don't be afraid to reach out to me!
                </p>
            </div>
            <div>
                <h1 style={{ margin: 100 }}>That being said I hope you enjoy!</h1>
            </div>
            <footer className="foot">
                <h1>Feel free to reach out to me at itsbz@umich.edu</h1>
            </footer>

        </div>
    )
}


export default About