import torf from './images2/torf.jpg'
import goodwork from './images2/goodwork.jpg'
import greatjob from './Images/greatjob.jpg'
import keepitup from './images2/keepitup.jpg'
import pmp from './images2/pmp.jpg'
import background from './Background/Lesson4.png'
import { Helmet } from "react-helmet";
import sticker from './sticker/tangeled.jpg'
import userService from '../services/user'
import { useDispatch, useSelector } from 'react-redux'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'

const Lesson4 = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])

    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    if (user.lesson < 4) {
        return (
            <div>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Please complete previous lesson.
                </p>
            </div>
        )
    }
    const p1 = () => {
        let inputElement = document.getElementById('name-input');
        let name = inputElement.value;
        let inputElemen = document.getElementById('nam-input');
        let nam = inputElemen.value;
        let inputElement1 = document.getElementById('name1-input');
        let name1 = inputElement1.value;
        let inputElemen1 = document.getElementById('nam1-input');
        let nam1 = inputElemen1.value;
        let outputElement = document.getElementById('output1');
        name = name.replace(/\s/g, '');
        name1 = name1.replace(/\s/g, '');
        nam = nam.replace(/\s/g, '');
        nam1 = nam1.replace(/\s/g, '');
        const a = (name === 'if(aced)')
        const b = (nam === 'System.out.println("ACED");')
        const c = (name1 === 'else')
        const d = (nam1 === 'System.out.println("Nope");')
        if (a && b && c && d) {
            if (user.lesson === 4 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p3 = () => {
        let inputElement = document.getElementById('name2-input');
        let name = inputElement.value;
        let inputElement1 = document.getElementById('name3-input');
        let name1 = inputElement1.value;
        let outputElement = document.getElementById('output3');
        name = name.replace(/\s/g, '');
        name1 = name1.replace(/\s/g, '');
        const a = (name === 'john<=5' || name === 'john<6')
        if (user.lesson === 4 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (a && name1 === 'System.out.println("Cena");') {
            if (user.lesson === 4 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again! Be careful! Read the print statements.'
        }
    }
    const p5 = () => {
        let inputElement = document.getElementById('name4-input');
        let name = inputElement.value;
        name = name.replace(/\s/g, '');
        const a = (name === 'score>=30');
        let inputElement1 = document.getElementById('name5-input');
        let name1 = inputElement1.value;
        name1 = name1.replace(/\s/g, '');
        const b = (name1 === 'elseif(score>=20){')
        let inputElement2 = document.getElementById('name6-input');
        let name2 = inputElement2.value;
        name2 = name2.replace(/\s/g, '');
        const c = (name2 === 'else{')
        let outputElement = document.getElementById('output6');
        if (user.lesson === 4 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (a && b && c) {
            if (user.lesson === 4 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }

    const p10 = () => {
        let inputElement = document.getElementById('name9-input');
        let name = inputElement.value;
        let inputElement1 = document.getElementById('name7-input');
        let name1 = inputElement1.value;
        let inputElement2 = document.getElementById('name8-input');
        let name2 = inputElement2.value;
        let outputElement = document.getElementById('output9');
        if (user.lesson === 4 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'One' && name1 === 'Both' && name2 === 'None') {
            if (user.lesson === 4 && user.question === 4) {
                const params = { id: user.id, lesson: false, number: 5 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const p11 = () => {
        let inputElement = document.getElementById('name10-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output10');
        name = name.replace(/\s/g, '');
        if (user.lesson === 4 && user.question <= 4) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'a&&b') {
            if (user.lesson === 4 && user.question === 5) {
                const params = { id: user.id, lesson: true, number: 5 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 4! Check out your new sticker and get started with Lesson 5 when you are ready!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    return (
        <div>
            <Helmet>
                <style>{'body { background-color: LightBlue; }'}</style>
            </Helmet>
            <title>Lesson 4</title>
            <div style={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover', height: 550, padding: 200, backgroundPosition: 'center'
            }}>
            </div>
            <div style={{ height: 100 }}>
                <h1 style={{ fontSize: 100, color: 'black', margin: 10, textAlign: 'center' }}>Lesson 4</h1>
                <p style={{ fontSize: 30, color: 'black', textAlign: 'center' }}><em>Conditional Statements and Booleans</em></p>
            </div>
            <div style={{ height: 70 }}>&nbsp;</div>
            <div style={{ margin: 100 }}>
                <h1>Introduction</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    What if we wanted the computer to not always do the same thing every time? For example, what if we want to print “Yes” if a
                    number is above 10, and “No” if it's less than 10? To do this, we must use Conditional Statements. This allows the code to base its actions
                    on certain conditions. In a conditional statement, we check to see if a boolean, is true or not. If it is, we will then
                    execute the code inside the brackets. If not, we’ll do something else.
                    It’s okay if you're confused right now. By the end of this lesson, you’ll understand a lot better!
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Here’s an example before we dive into more detail.
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>
                            <pre>
                                if(true){"{"}<br />
                                {"  "}System.out.println(“Hello World);<br />
                                {"}"}
                            </pre>
                        </code>
                    </p>
                    This code checks if the boolean value inside the “if()” is true or false. If it’s true, and since it is, it
                    will print out Hello World! Note that there's no semicolon after the "if(true){"{"}", or after the closing bracket "{"}"}"
                </p>
                <div>
                    <h1>Booleans</h1>
                    <img src={torf} alt="TorF" style={{ height: 375, margin: 100, alignSelf: 'flex-end' }} />
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        To understand how conditional statements work, we need a greater understanding of booleans.
                        This is because to write an if statement, we need to pass in a boolean value to the parentheses.
                        We will demonstrate here how to create such boolean values.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        The most basic idea is directly using a boolean variable. For example: <br />
                        <code>
                            boolean x=true;<br />
                        </code>
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Then, we can use if(x) as our conditional statement. We are passing in x as our boolean expression. In this case, x is true of course.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Another example of a boolean expression is the second line of code from the following program.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>
                            int x=0;<br />
                            x==10;<br />
                        </code>
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        If we simply wrote x=10, we would be setting x to ten. Using "==", we will generate true if x is equal to ten.
                        It will generate false if x is not equal to ten (You can think of it as checking if two values are equal).<br />
                        Thus, if we wrote: <br />
                        <p style={{ lineheight: 2.0, fontSize: 20 }}>
                            <code>
                                <pre>
                                    if(x==10){"{"}<br />
                                    {"  "}x=1;<br />
                                    {"}"}
                                </pre>
                            </code>
                        </p>
                        This code checks to see if x is equal to 10. Since it isn’t, it will NOT execute the “x=1” statement. Hence, after the if statement,
                        x is still 0. Be sure you know the difference between == and =.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        What if we wanted to do something if x isn’t equal to ten? Then, we would use !=, which means “not equal to”.<br />
                        <p style={{ lineheight: 2.0, fontSize: 20 }}>
                            <code>
                                <pre>
                                    if(x!=10){"{"}<br />
                                    {"  "}System.out.println("Hello World");<br />
                                    {"}"}<br />
                                </pre>
                            </code>
                        </p>
                        This will only print Hello World if x is not equal to ten.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Our final case is to use {"< or >"} to generate a boolean. For example, let’s say we have an integer grade that
                        stores a grade. We can write if(grade{">"}90) for example. If grade{">"}90, it will be true. We use {">"} and {"< "}
                        just like we would in math. We can also use {">="} to symbolize greater than or equal to. {"<="} would symbolize
                        less than or equal to.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        To conclude this section, we will introduce a rather advanced topic: combining booleans. Let’s say we
                        have two booleans a and b. Suppose we only want to do something if
                        both a and b are true. We can write if (a&&b). && is the symbol for and. An example where this may
                        come in handy is if you want to check if a number is greater than 1 but less than 4.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        On the other hand, let’s say we want to do something if at least one of a or b is true. In that
                        case, we would write a||b. This means a or b. || symbolizes or. a||b is true if at least one of a or
                        b is true. Both don’t need to be true, but the condition will still be satisfied if both are true.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        One more important topic. What if we want the opposite of a boolean? What if we want to express if(a
                        isn't true?).
                        Then, we use !a. ! makes the current boolean the opposite value. If a is true,
                        !a is false.
                        Likewise, if a is false, !a is true.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        To summarize, we can directly call a boolean, or use the symbols ==, {">, <, >=, <=, "}
                        != to generate a boolean. We can combine multiple booleans with && or ||. ! means "not". It makes a
                        true value false and a false value true. </p>
                    <img src={goodwork} style={{ height: 400, margin: 100 }} alt="GOAT." />
                </div>
                <div>
                    <h1>If Statements</h1>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        We've already mentioned if statements earlier. They are the ones that use "if".
                        We will dive a little more in-depth here.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>
                            <pre>
                                if(boolean){"{"}<br />
                                {"  "}System.out.println(“true”);<br />
                                {"}"}
                            </pre>
                        </code>
                        If the boolean inside the parentheses is true, then we will run the statement inside the brackets,
                        which is to print true. If it’s false, then we skip over the if statement and move on.
                    </p>
                </div>
                <div>
                    <h1>Else Statements</h1>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Let’s assume that we have two cases, the first being an integer variable x equals 10 and the second being it doesn't equal 10.
                        In the program below, we want to set x=10 if x isn’t already 10. If it is already 10, we want to
                        print a statement confirming that its value is 10. Let’s try using two if statements for this.
                        <p style={{ lineheight: 2.0, fontSize: 20 }}>
                            <code>
                                <pre>
                                    if(x!=10){"{"}<br />
                                    {"  "}x=10;<br />
                                    {"}"}<br />
                                    if(x==10){"{"}<br />
                                    {"  "}System.out.println("It's already ten!");<br />
                                    {"}"} <br />
                                </pre>
                            </code>
                        </p>
                        Can you spot the mistake? If x is not equal to 10, we first assign it to equal to 10.
                        Now x is equal to 10. Next, when the computer moves on to the next if statement. Since x is now equal to 10, the boolean is true and
                        the computer will print the confirmation. Since x was not equal to ten at the start, we don't want it to print the confirmation.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        If we want one or the other but not both, we can use the else statement. Here’s the correct code.<br />
                        <p style={{ lineheight: 2.0, fontSize: 20 }}>
                            <code>
                                <pre>
                                    {"if(x!=10){"}<br />
                                    {" "}x=10;<br />
                                    {"}"}<br />
                                    {"else{"}<br />
                                    {" "} System.out.println("It's already ten!");<br />
                                    {"}"}<br />
                                </pre>
                            </code>
                        </p>
                        If x!=10 initially, the code assigns x to 10 from the line "x=10". It WON'T print yes as well. The else statement
                        ensures that if{"(x!=10)"}, it will run x=10 and ONLY x=10. To that end, if x==10, it will
                        print the confirmation message! <br />
                        Note that we can only write an else statement directly after an if statement. If the if boolean is false, it will run the command
                        within the else statement.
                    </p>
                    <img src={keepitup} style={{ height: 400, margin: 50 }} alt="GOAT." />
                </div>
                <div>
                    <h1>Else-If Statement</h1>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Let’s say we want to test many conditions, but only want one to run. In this case, we will use else-if
                        statements along with if statements.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        <code>
                            <pre>
                                if(x==1){"{"}<br />
                                {" "} System.out.println(“one”);<br />
                                {"}"}<br />
                                else if(x==2){"{"}<br />
                                {" "} System.out.println(“two”);<br />
                                {"}"}<br />
                                else{"{"}<br />
                                {" "} System.out.println(“invalid”);<br />
                                {"}"}                      <br />
                            </pre>
                        </code>
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Essentially, else-if statements function as mini else statements. They must appear after an if statement. If the if statement is true, it will ignore all the else-ifs.
                        If not, the computer will check all the else-if booleans until it finds one that's true. When it does, it runs
                        that command and ends without checking the others.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Assume none of the else-ifs are true. If we include an else statement at the end, our code will always run the command
                        in the else statement.
                    </p>
                    <p style={{ lineheight: 2.0, fontSize: 20 }}>
                        Here’s a quick summary before we start our practice problems.<br />
                        &#x2022; If is the initial statement. If the boolean is true, execute the body of the if statement.<br />
                        &#x2022; Else if statements after an if statement function such that assuming the if is false, execute the body of the first
                        else if that's true.<br />
                        &#x2022; If none of the if or else if booleans are true, then execute the body of the else statement
                        command.<br />
                    </p>
                    <img src={greatjob} style={{ height: 400, margin: 100 }} alt="GOAT." />
                </div>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Functions/New Problem Format</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Before we start the next set of practice problems, I would like to introduce a new question format.<br />
                    <code>
                        <pre>
                            void q1(boolean a){"{"}<br />
                            {"  "}Insert your code here.<br />
                            {"}"}<br />
                        </pre>
                    </code>
                    This has a different header. In Java, it's called a function. We write it outside of the
                    main code.</p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Like a function, we have an input and an output. What's inside the (), in this case, boolean a
                    is something that we don't know. It's our input and can take on any valid value, a=true or a=false. We will have to
                    write our code without knowing the value of this boolean. Just like in math, we oftentimes don't know the input
                    to a function.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    As far as the other components, q1 is the name of the question. Void is the return type. Later, we may ask you to return something.
                    For now, don't worry about this. Note: Returning and printing are two DIFFERENT things in Java. Thus, we can print
                    stuff in a function with a return type void.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    I’m going to start using functions so I can specify variables that can take on any value that you must deal with in your code.
                    In the above case, I’m giving you a boolean named a that you won’t know what the value of. For example, in the example code up
                    above, suppose we had to print out the value of a. We would simply just write System.out.println(a); In other words, assume
                    whatever is inside the parenthesis is already declared but has an unknown value. Feel free to reach out if you need help
                    understanding!
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Example Question:
                    If boolean a is true, print “I love you”. If it isn’t, print out “NO”.</p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Answer:<br />
                    <code>
                        <pre>
                            void q1(boolean a){"{"}<br />
                            {"  "}if(a){"{"}<br />
                            {"      "}System.out.println(“I love you”)<br />
                            {"  }"}<br />
                            {"  "}else{"{"}<br />
                            {"      "}System.out.println(“NO”);<br />
                            {"  }"}<br />
                            {"}"}<br />
                        </pre>
                    </code>
                </p>
            </div>
            <img src={pmp} style={{ height: 400, margin: 50 }} alt="GOAT." />
            <div style={{ margin: 75 }}>
                <h1>Practice Problems</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Note, some questions will use the header in the previous sections. Others will just state the variables you will
                    need to use in your solution.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. Given a boolean aced, print out “ACED” if aced is true. If it isn’t true, print out “Nope”.<br />
                    <pre>
                        void q1(boolean aced){"{"}<br />
                        <input id="name-input" style={{ width: 300 }} />{"{"}
                        <br />
                        {"  "}<input id="nam-input" style={{ width: 300 }} /><br />{"}"}<br />
                        <input id="name1-input" style={{ width: 300 }} />{"{"}<br />
                        {"  "}<input id="nam1-input" style={{ width: 300 }} /><br />{"}"}<br />
                        {"}"}<br />
                        <button onClick={p1}>
                            Check
                        </button><br />
                    </pre>
                    <p id="output1"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    2. Given an integer "john", print “Cena” if john is greater than 5. Else, print out “Legend”.<br />
                    Header not shown. Assume int john; is already declared.<br />
                    <pre>
                        if{"("}<input id="name2-input" style={{ width: 300 }} />{"){"}<br />
                        {"  "}System.out.println("Legend");<br />
                        {"}"}<br />
                        else{"{"}<br />
                        {"  "}<input id="name3-input" style={{ width: 300 }} /><br />
                        {"}"}<br />
                        <button onClick={p3}>
                            Check
                        </button>
                    </pre>
                    <p id="output3"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. Given an integer variable named score, print “Great” if the score is greater than or equal to 30. If not, print “Okay”
                    if the score is greater than or equal to 20. Else, print “Poor”. <br />
                    <pre>
                        if{"("}<input id="name4-input" style={{ width: 300 }} />{"){"}<br />
                        {"  "}{'System.out.println("Great");'}<br />
                        {"}"}<br />
                        <input id="name5-input" style={{ width: 300 }} /><br />
                        {"  "}{'System.out.println("Okay");'}<br />
                        {"}"}<br />
                        <input id="name6-input" style={{ width: 300 }} /><br />
                        {"  "}{'System.out.println("Poor");'}<br />
                        {"}"}<br />
                        <button onClick={p5}>
                            Check
                        </button>
                        <p id="output6"></p>
                    </pre>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    4. Describe what the following code prints given the values of a and b.<br />
                    <code>
                        <pre>
                            void wack(int a,int b){"{"}<br />
                            {"  "}if(a{">"}100&&b{">"}100){"{"}<br />
                            {"      "}System.out.println(“Both”);<br />
                            {"  "}{"}"}<br />
                            {"  "}else if(a{">"}100||b{">"}100){"{"}<br />
                            {"    "}System.out.println(“One”);<br />
                            {"  "}{"}"}<br />
                            {"  "}else{"{"}<br />
                            {"      "}System.out.println(“None”);<br />
                            {"  "}{"}"}<br />
                            {"}"}  <br />
                        </pre>
                    </code>
                    a=200 and b=101<br />
                    <input id="name7-input" style={{ width: 300 }} /><br />
                    a=100 and b=99<br />
                    <input id="name8-input" style={{ width: 300 }} /><br />
                    a=1 and b=101<br />
                    <input id="name9-input" style={{ width: 300 }} />
                    <button onClick={p10}>
                        Check
                    </button>
                    <p id="output9"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    5. Given two boolean values a and b, print “Both teams won!” if both a and b are true.<br />
                    <pre>
                        if{"("}<input id="name10-input" style={{ width: 300 }} />{"){"}<br />
                        {'  System.out.println("Both teams won!");'}<br />
                        {"}"}<br />
                        <button onClick={p11}>
                            Check
                        </button>
                    </pre>
                    <p id="output10"></p>
                </p>
                {user.lesson > 4 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} /><br />
                    <Link to="/stickers">Stickers</Link>
                </p>) : (<div></div>)}
                {user.lesson > 4 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}><Link to="/lesson5">Lesson 5</Link>
                </p>) : (<div></div>)}
            </div>
        </div>
    )
}

export default Lesson4