import hello from './Images/hello.jpg'
import world from './Images/world.jpg'
import amazing2 from './images2/amazing.jpg'
import background from './Background/Lesson1.png'
import sticker from './sticker/frozen.jpg'
import { useDispatch, useSelector } from 'react-redux'
import userService from '../services/user'
import { setU, incrementQuestion, incrementLesson } from '../reducers/userreducer'
import { Link } from "react-router-dom"
import { useEffect } from 'react'


const Lesson1 = () => {
    var code1 = "public static void main (String args[]){"
    var code2 = "System.out.println(“Hello World”);"
    const dispatch = useDispatch()
    useEffect(() => {
        const loggedUserJSON = window.localStorage.getItem('loggedUser')
        if (loggedUserJSON) {
            const user = JSON.parse(loggedUserJSON)
            userService.getUser({ id: user.id }).then(user => dispatch(setU(user)))
        }
        // eslint-disable-next-line
    }, [])
    const user = useSelector(state => state)
    if (user == null) {
        return <div></div>
    }
    const sayHello = () => {
        let inputElement = document.getElementById('name-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output');
        if (name === 'System.out.println("Disney is Cool");') {
            if (user.lesson === 1 && user.question === 1) {
                const params = { id: user.id, lesson: false, number: 2 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const fix = () => {
        let inputElement = document.getElementById('name1-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output1');
        if (user.lesson === 1 && user.question === 1) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'System.out.println("Hello World");') {
            if (user.lesson === 1 && user.question === 2) {
                const params = { id: user.id, lesson: false, number: 3 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const tf = () => {
        let inputElement = document.getElementById('name2-input');
        let name = inputElement.value;
        let outputElement = document.getElementById('output2');
        if (user.lesson === 1 && user.question <= 2) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'True' || name === 'true' || name === 'True.' || name === 'true.') {
            if (user.lesson === 1 && user.question === 3) {
                const params = { id: user.id, lesson: false, number: 4 }
                userService.increment(params)
                dispatch(incrementQuestion())
            }
            outputElement.innerText = 'Correct!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }
    const kevin = () => {
        let inputElement = document.getElementById('name3-input');
        let name = inputElement.value;
        let inputElement1 = document.getElementById('name4-input');
        let name1 = inputElement1.value;
        let outputElement = document.getElementById('output4');
        if (user.lesson === 1 && user.question <= 3) {
            outputElement.innerText = 'Please solve the previous question(s) first!'
        }
        else if (name === 'System.out.println("Kevin");' && name1 === 'System.out.println("Rishi");') {
            if (user.lesson === 1 && user.question === 4) {
                const params = { id: user.id, lesson: true, number: 2 }
                userService.increment(params)
                const params1 = { id: user.id, lesson: false, number: 1 }
                userService.increment(params1)
                dispatch(incrementLesson())
            }
            outputElement.innerText = 'Congrats on Finishing Lesson 1! Check out your new sticker and get started with Lesson 2 when you are ready!'
        }
        else {
            outputElement.innerText = 'Try Again!'
        }
    }

    return (
        <div>
            <meta charSet="utf-8" />
            <title>Lesson 1</title>
            <link rel="stylesheet" href="src/main.css" />
            <div style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', height: 400, padding: 300, backgroundPosition: 'center' }}>
            </div>
            <div style={{ margin: 100 }}>
                <h1>Welcome! </h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <div>
                        We are going to learn how to write our first line of Code! We will be using the language Java. You may have heard of other languages such as Python and C++.
                        Java is a good first language. If you take computer science in high school, it will likely be taught in Java.
                    </div>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <div>
                        At the end of the lesson, you will win a sticker!
                    </div>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>

                    <div>
                        Let’s start by learning the famous “Hello World” code.
                        "Hello World" is always the first program one learns to write. As implied by the name, we want to write a program such that
                        our computer says hello! The code is shown below. It prints out the words "Hello World".
                        We’ll go more into depth about how the code works.
                    </div>
                </p>

                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <code>
                        <pre>
                            <div>{code1}</div>
                            <div>{"  "}{code2}</div>
                            {"}"}
                        </pre>
                    </code>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    When I first saw this code, I could infer how it worked, but had many questions. In particular, I was never sure of what
                    “public static void main (String args[])” meant.
                    For now, Don’t worry! Let's call it the “Main Header”.
                    We call it the Main Header because we write the "main code" within it. Don’t worry about the main header
                    for this course. You will gradually understand it as you get more in-depth with this course and will fully understand it if you continue coding!
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    After the header, we must include brackets, {"{ }"}. When we write the main header, we put the code
                    in between our brackets. Make sure you close the opening bracket. We can’t have a {"{"} with no {"}"}.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Now that we have our header and our brackets, it's time to write our print command. System.out.println(“Hello World”);
                    is the command that prints out “Hello World”.
                    System.out.println means we are printing to the system. The “ln” at the end of "print" tells our computer to print a new line
                    before the next print statement. Thus, when we call another print statement, the output will be printed on a new line.
                    For now, always include the "ln".
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    We write what we want to print inside the (). In this case,
                    we write “Hello World” within the ().
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    The semicolon is included after every line of Java code. It's to indicate the end of a line of code.
                    Don’t use it after the header. (we’ll go more in-depth into
                    when to use and not use a semicolon). For now, make
                    sure that after you print something, you put a semicolon at the end of the line. When we learn new techniques, we will
                    provide examples so you know when and when not to use a semicolon.
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Be exact! Don’t forget anything, and make sure you don’t mix up upper and lower cases (yes, that matters!). Be sure that
                    what’s in the " " matches exactly what you want to print. Now, Let’s practice!
                </p>
            </div>
            <div style={{ margin: 100 }}>
                <img src={hello} alt="hello" style={{ height: 375, margin: 40 }} />
                <img src={world} alt="world" style={{ height: 375, margin: 40 }} />
            </div>
            <div style={{ margin: 75 }}>
                <h1>Practice Problems</h1>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    Let's practice! These problems involve typing a line of code or the answer in the input box.
                    Once you finish, you may check your answer. Some questions require multiple lines of code.
                    In this case, you should complete each line of code before you check your answer. Please answer in order.
                    Once you finish all
                    the problems, you will win a Sticker and gain access to the next Lesson. Your progress will be saved,
                    but it's recommended to finish one lesson at a time. In case you forgot, you can check your current progress on the home page.
                    Now let's roll!
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    1. Print “Disney is Cool".<br />
                    <input id="name-input" style={{ width: 300 }} />
                    <button onClick={sayHello}>
                        Check
                    </button>
                    <p id="output"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    2. This “Hello World” Code is incorrect. Input the correct version. <br />
                    Incorrect code: System.ot.println("Hello World")<br />
                    <input id="name1-input" style={{ width: 300 }} />
                    <button onClick={fix}>
                        Check
                    </button>
                    <p id="output1"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    3. True or false, there needs to be the same amount of {' "{" (opening brackets) as "}" (closing brackets)'} in a
                    code.<br />
                    <input id="name2-input" style={{ width: 300 }} />
                    <button onClick={tf}>
                        Check
                    </button>
                    <p id="output2"></p>
                </p>
                <p style={{ lineheight: 2.0, fontSize: 20 }}>
                    4. Print the following:<br />
                    Kevin <br />
                    Rishi <br />
                    <input id="name3-input" style={{ width: 300 }} /> <br />
                    <input id="name4-input" style={{ width: 300 }} />
                    <button onClick={kevin}>
                        Check
                    </button>
                    <p id="output4"></p>
                </p>
                {user.lesson > 1 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}>
                    <img src={sticker} alt="hello" style={{ height: 375, margin: 40 }} /><br />
                    <Link to="/stickers">Stickers</Link>
                </p>) : (<div></div>)}
                <p>Note: You may have to scroll to the top of the next lesson, as the router doesn't automatically route to the top. Keep this in mind for the future as well.</p>
                {user.lesson > 1 ? (<p style={{ lineheight: 2.0, fontSize: 20 }}><Link to="/lesson2">Lesson 2</Link>
                </p>) : (<div></div>)}
                <img src={amazing2} alt="Hello" style={{ height: 375, margin: 10 }} />
            </div>
        </div>
    )
}

export default Lesson1